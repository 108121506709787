import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { fetchRestaurantDetails, tablesToChange as fetchTablesToChange, changeTables } from '../actions/restaurantActions';
import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TablesModal  from '../components/ChangeTables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTriangleExclamation, faShareFromSquare, faClock, faHouse, faFilePen } from '@fortawesome/free-solid-svg-icons';
import {
  fetchOpenTime,
  fetchCreateReservation,
  fetchReservationLimitPeopleBaby,
  fetchEditReservation,
  fetchReservationCustomerMenu,
  checkIfrestaurantIsOpenToday,
  getRestaurantTimeZone,
  ResetReservation,
  fetchResetReservation,
  fetchCancelReservation,
  fetchReservationActive,  
} from '../actions/reservationsActions';
import styled from 'styled-components';
import CustomDatePicker from '../components/CustomDatePicker';
import CustomSelect from '../components/SelectableCustom';
import {getDialCode, getCountryCode} from '../components/CountryCode';
import Loader from '../components/Loader'
import TermsAndConditions from '../components/TermsAndConditions'
import ScrollInputOptions from '../components/ScrollInputOptions';
import StepThreeReservations from '../components/StepThreeReservations';
import AnimatedCheck from '../components/CheckMarkAnimation'
import { isValidNumber } from 'libphonenumber-js';
import StepBar from '../components/StepBar'
import NumberOfPeopleSelector from '../components/NumberOfPeopleSelector'
import ChooseTime from '../components/ChooseTime'
import moment from 'moment-timezone';



const InputOverlay = styled.input`
  position: absolute;
  top: 33px;
  font-size:16px;
  left: 105px; 
  height: 36px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
`;


const Wrapper = styled(Container)`
  max-width: 500px;
  margin: 10px auto;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  min-height:550px;
`;

const ModalBackgroundCheck = styled.div`
  width:50px;
  height:50px;
  z-index: 1002;
`;

const ContainerCheck = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center; // for vertical centering
  justify-content: center; // for horizontal centering
`;



const Title = styled.h3`
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledCard = styled.div`
  border-radius: 5px;
  max-width: 500px;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 385px;
  background-color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align content to the top */
  align-content: flex-start; /* Ensure the whole content stays at the top */
  flex-wrap: wrap;

  & > * {
    margin: 0; /* Reset any default margin */
    margin-bottom: 10px; /* Add some space between wrapped items */
  }
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`;


const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    max-width: 100%;
    box-sizing: border-box;
    overflow:hidden;
  }
`;



const DivFlexEnd = styled.div`
  display: flex;  
  width:100%;
  justify-content:end;
`;

const DivFlexStart = styled.div`
  display: flex; 
  align-items:center; 
  width:100%;
  justify-content:start;
`;


const Extras = styled.div`
  display: flex;
  align-items:center;   
  padding:5px;
  margin-top:10px;
  border-radius:5px;
  gap: 10px;
  border: 1px solid gray;
  max-width:100px;
  justify-content:center;
  cursor:pointer;
  color: ${props => (props.isExtrasOpen) ? '#ffffff'  : 'black'};
  background-color: ${props => (props.isExtrasOpen) ? '#58d18c'  : 'white'};
`;

const ExtrasInside = styled.div`
  padding:5px;
  width:100%;
  margin-top:10px;
  border-radius:5px;
  border: 1px solid gray;
  cursor:pointer;
  color: ${props => (props.status) ? '#ffffff'  : 'black'};
  background-color: ${props => (props.status) ? '#58d18c'  : 'white'};
`;


const CustomDateContainer = styled.div`
  position: fixed;  // fixed will keep it centered even when the page is scrolled
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);  // centers the div
  z-index: 5000;
`;

const ModalBackgroundToCustomDate = styled.div`
  position: fixed;
  z-index: 1005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const TableBox = styled.div`
  display: inline-block;
  margin: 1px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background-color: #3ECF95;
  color: white;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box; 
`;



function getTableInfo(selectedTablesList, allTablesList) {
  let tableInfo = {};


  
  Object.keys(selectedTablesList).forEach(zoneName => {
    const tableNumbers = selectedTablesList[zoneName].map(table => table.number);
    
    allTablesList.forEach(tablesInZone => {
      if (tablesInZone[0] === zoneName) {
        tableNumbers.forEach(tableNumber => {
          const tableDetails = tablesInZone.find(table => table.split(';')[0] === tableNumber);
          if (tableDetails) {
            if (tableInfo[zoneName]) {
              tableInfo[zoneName].push(tableDetails);
            } else {
              tableInfo[zoneName] = [tableDetails];
            }
          }
        });
      }
    });
  });


  // Convert the object into a list of lists
  let tableInfoList = [];
  Object.keys(tableInfo).forEach(zone => {
    tableInfoList.push([zone, ...tableInfo[zone]]);
  });

  return tableInfoList;
}


function listToSelectedTablesFormat(zoneName, tableNumbers) {
  if (!Array.isArray(tableNumbers)) {
    throw new Error("Expected parameter to be an array");
  }

  const tables = tableNumbers.map(tableNumber => ({
    number: tableNumber,
    status: "0",
  }));

  return {
    [zoneName]: tables,
  };
}
const ReservationScreen = ({from_restaurant=false, creating=true, 
                           isEditModalOpen=false, setIsEditModalOpen=false, 
                           restaurantId=false, token_from_restaurant=false,
                           handleCloseReservationModal, setCreating, reset, editModalRef,
                           setTokens=false, reservation_or_waiting_list='reservation'}) => {
  const id = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState( token_from_restaurant  ? token_from_restaurant: id.token? id.token :  false);
  const [restaurant_id, setRestaurant_id] = useState(id.restaurant_id ? id.restaurant_id : restaurantId);
  const [startDateWS, setStartDateWS] = useState(false);
  const [babyChairs, setBabyChairs] = useState(false);
  const [numBabyChairs, setNumBabyChairs] = useState(0);
  const [time, setTime] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [numPeople, setNumPeople] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState('');
  const [message, setMessage] = useState('');
  const maxMessageLength = 150;
  const [accessability, setAccessability] = useState('');
  const [accessabilities, setAccessabilities] = useState(['Cadeira de Rodas']);
  const [tablesUsed, setTablesUsed] = useState([]);
  const [usedTablesDic, setUsedTablesDic] = useState({});
  const [fullName, setFullName] = useState('');
  const [placeOfTable, setPlaceOfTable] = useState('');
  const [placeOfTableWS, setPlaceOfTableWS] = useState(false);
  const [fourthStage, setFourthStage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [countryCode, setCountryCode] = useState("us");
  const [countryCodeNumber, setCountryCodeNumber] = useState('351');
  const [phone, setPhone] = useState('');
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');

  const [email, setEmail] = useState('');
  const [emailWS, setTheEmailWS] = useState(false);

  const [subscription, setSubscription] = useState({});
  const [cd, setCd] = useState('');
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsWS, setAcceptTermsWS] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [fullRestaurant, setFullRestaurant] = useState(false);
  const [isExtrasOpen, setIsExtrasOpen] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [isAccessOpen, setIsAccessOpen] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [editingStartFalse, setEditingStartFalse] = useState(true);
  const [showTablesModal, setShowTablesModal] = useState(false);
  const [showModal, setShowModal] = useState(false); // cancel modal
  const [ignoreLimitations, setIgnoreLimitations] = useState(false);
  const [selectedTablesSave, setSelectedTablesSave] = useState(false);
  const [reservationObj, setReservationObj] = useState({});


  const [update, setUpdate] = useState(0);

  const reservationCancel = useSelector(state => state.cancelReservation)
  const { cancelMessage, isLoadingCancel } = reservationCancel

  


  useEffect(() => {
    console.log('update state: ', update)
  },[update])

  useEffect(() => {
    if (!from_restaurant) {
      dispatch(fetchReservationActive());
    }
  },[from_restaurant])


  useEffect(() => {
    if (Array.isArray(tablesUsed) && tablesUsed[0] == '' && tablesUsed.length > 1) {
      setTablesUsed(tablesUsed.slice(1, tablesUsed.length))
    }
  },[tablesUsed])
  
  useEffect(() => {
    if (token) {
      setEditingStartFalse(true)
    } else {
      setEditingStartFalse(true)
    }
  },[token, editingStartFalse])

  useEffect(()=> {
    if (restaurantId) {
      setRestaurant_id(restaurantId);
    }
    
  }, [restaurantId])

  const getDateInTimezone = (timezone) => {
    // Get the current date in the specified timezone
    const now = new Date();
    const dateInTimezone = new Date(now.toLocaleString("en-US", { timeZone: timezone }));

    // Set the time to 00:00:00
    dateInTimezone.setHours(0, 0, 0, 0);

    return dateInTimezone; // Returns a valid Date object
  };
    

  useEffect(()=> {
    if (creating) {
      setToken('');
      setEstimatedTime('');
      setBabyChairs(false);
      setNumBabyChairs(0);
      setMessage('');
      setEmail('');
      setNumPeople(0);
      setTablesUsed([]);
      setSelectedTablesSave('');
      setAccessability('');
      setPlaceOfTable('');
      setUsedTablesDic({});
      setStartDate(null);
      setTime(null);
      if (reservation_or_waiting_list === 'waiting_list') {
        setStartDate(getDateInTimezone(restaurants.timezone));
        setCurrentStep(1);
      } else {
        setCurrentStep(0);
      }
      if (from_restaurant) {
        setFullName('');
        setPhoneWithoutCountryCode('');
      }
    }
  }, [creating,  token, reservation_or_waiting_list]);

  useEffect(() => {
    if (reservation_or_waiting_list === 'waiting_list' && placeOfTable !== '') {
        const getCurrentTimeInTimezone = (timezone) => {
            const now = new Date();
            // Convert to the restaurant's timezone
            const timeInTimezone = new Date(
                now.toLocaleString('en-US', { timeZone: timezone })
            );

            // Format as hh:mm
            const hours = String(timeInTimezone.getHours()).padStart(2, '0');
            const minutes = String(timeInTimezone.getMinutes()).padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        // Assuming you have access to the restaurant's timezone
        const restaurantTimezone = "Europe/London"; // Replace this with the actual timezone
        const currentTime = getCurrentTimeInTimezone(restaurantTimezone);
        console.log('currentTime inside waiting list place of table : ', currentTime)
        setTime(currentTime); // Set the current time in hh:mm format
        setCurrentStep(3);
    }
}, [placeOfTable]);

  const [fullNameWS, setFullNameWS] = useState(false);
  const [numPeopleWS, setNumPeopleWS] = useState(false);
  const [timeWS, setTimeWS] = useState(false);
  const [phoneWS, setPhoneWS] = useState(false);

  useEffect(() => {
    dispatch(checkIfrestaurantIsOpenToday(restaurant_id));
    dispatch(getRestaurantTimeZone(restaurant_id));
  }, [from_restaurant, restaurant_id]);

  useEffect(() => {
    dispatch(fetchReservationLimitPeopleBaby(restaurant_id, startDate, time));
  },[startDate, time, restaurant_id])



  const [currentStep, setCurrentStep] = useState(0);
  
  useEffect(() => {
    if (!creating) {
      setCurrentStep(3);
    }
  },[])
  


  const editReservations = useSelector((state) => state.editReservation);
  const { editReservation, isLoadingEditReservation  } = editReservations;

  const openTimes = useSelector((state) => state.openTime);
  const { openTime, isLoadingOpenTime, errorOpenTime } = openTimes;

  const LimitsPB = useSelector((state) => state.reservationLimitsPeopleBaby);
  const { limitPeopleBaby } = LimitsPB;

  const reservation_createdd = useSelector((state) => state.createReservation);
  const { reservation_created, toastCreateUpdate , isLoading, error, createReservationFailed  } = reservation_createdd;

  const restaurantDetails = useSelector(state => state.restaurantDetails);
  const { restaurants } = restaurantDetails;

  const tablesToChange= useSelector((state) => state.availableTablesToChange);
  const { tables = [], isLoadingTables, errorTables } = tablesToChange;



  useEffect(() => {
    dispatch(fetchRestaurantDetails(restaurant_id));
  }, [dispatch, restaurant_id]);

  useEffect(() => {
    setToken(token_from_restaurant);
    setSelectedTablesSave(false);
    console.log('token from restaurant updated')
  },[token_from_restaurant]);

  const reservation_info = useSelector(state => state.reservationCustomersMenu)
  const {  reservation  } = reservation_info

useEffect(() => {
  if (!creating && token != '') {
    dispatch(fetchReservationCustomerMenu(token, 'no'));
  } else {
    dispatch(ResetReservation());
  }
},[creating, token]);

useEffect(() => {
  if (!creating && token != '') {
      if (reservation && restaurant_id !== reservation.restaurant) {
        setRestaurant_id(reservation.restaurant);
      }
  }
},[reservation]);

useEffect(() => {
  let phoneWithoutCountryCoda;
  if (reservation && reservation.phone_number) {
    const countryCodeLength = countryCodeNumber.length;
    let phoneNumber = reservation.phone_number;
    // Remove leading plus if it exists
    if (phoneNumber[0] === '+') {
      phoneNumber = phoneNumber.slice(1);
    }
    // Remove leading country code number
    phoneWithoutCountryCoda = phoneNumber.slice(countryCodeLength);
  }
  
  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
  
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  }

  if (reservation && !creating && !deepEqual(reservation, reservationObj) || reservation && fullName !== reservation.full_name) {
    setFullName(reservation.full_name);
    const dateObject = new Date(reservation.date);
    setStartDate(dateObject);
    if (reservation.table_place_preference !== null || reservation.table_place_preference !== '') {
      setPlaceOfTable(reservation.table_place_preference ? reservation.table_place_preference.place_of_table : 'Qualquer Sala');
    } else {
      setPlaceOfTable('');
    }
    console.log('time: ', time);
    setTime(reservation.time);
    setEstimatedTime(reservation.estimatedtime);
    setMessage(reservation.message);
    setNumPeople(reservation.number_of_people);
    setTablesUsed(reservation.tablesused.split(','))
    setCountryCodeNumber(reservation.country_code)
    setPhone(reservation.phone_number)
    setPhoneWithoutCountryCode(phoneWithoutCountryCoda);
    setEmail(reservation.email)
    setAccessability(reservation.accessability === 'wheel_chair' ? 
    'Cadeira de Rodas' : 'not_needed')
    if (reservation.baby_chair > 0){
      setBabyChairs(true);
      setNumBabyChairs(reservation.baby_chair);
    } else {
      setBabyChairs(false);
    }
    if (reservation.accessability != 'not_needed'){
      setIsAccessOpen(true);
    } else {
      setIsAccessOpen(false);
    }
    let usedTablesss = reservation.tablesused.split(',');
    let selectedTablesFormat = {}
    if (Array.isArray(usedTablesss)) {
      selectedTablesFormat = listToSelectedTablesFormat(reservation.table_place_preference ? reservation.table_place_preference.place_of_table : 'Qualquer Sala', usedTablesss);
    } 
    setUsedTablesDic(selectedTablesFormat)
    let usedTabless;
      if (  ignoreLimitations && creating || reservation.tablesused === '') {
        usedTabless = 'no_tables';
      } else {
        usedTabless = reservation.tablesused;
      }
    const inputDate = new Date(dateObject);
    dispatch(fetchTablesToChange(restaurant_id, 
      `${inputDate.getFullYear()}-${String(inputDate.getMonth() + 1).padStart(2, '0')}-${String(inputDate.getDate()).padStart(2, '0')}`, 
      reservation.time, reservation.table_place_preference ? reservation.table_place_preference.place_of_table : 'Qualquer Sala', 
      usedTabless, reservation.number_of_people));
  setReservationObj(reservation);
  }
},[reservation, token, creating]);

    //() => handleShowTablesModal(reservation.restaurant, 
    //dateFilter, reservation.time, reservation.table_place_preference.id, 
    //reservation.tablesused, reservation.table_place_preference.place_of_table, 
    //reservation.token, reservation.estimatedtime)
    // Adjust the handleShowTablesModal function

    const handleShowTablesModal = (show=true) => {
      let usedTablesss = tablesUsed;
      let selectedTablesFormat = {}
      if (Array.isArray(usedTablesss)) {
        selectedTablesFormat = listToSelectedTablesFormat(placeOfTable, usedTablesss);
      } 
      setUsedTablesDic(selectedTablesFormat)
      let usedTabless;
      if (  ignoreLimitations && creating || reservation.tablesused === '') {
        usedTabless = 'no_tables';
      } else {
        usedTabless = reservation.tablesused;
      }
      const inputDate = new Date(startDate);
      dispatch(fetchTablesToChange(restaurant_id, 
        `${inputDate.getFullYear()}-${String(inputDate.getMonth() + 1).padStart(2, '0')}-${String(inputDate.getDate()).padStart(2, '0')}`, 
        time, placeOfTable, usedTabless, numPeople));
      if (show) {
        setShowTablesModal(true);
      }
    };

    const handleModalSave = (selectedTables) => {
      const fullTableInfo = getTableInfo(selectedTables, tables);
      dispatch(changeTables(token, fullTableInfo, restaurant_id));
      setShowTablesModal(false);

      toast('Reserva Editada', { autoClose: 1000, zIndex: 1000 });
    setShowCheck(true); // Show the check mark animation
      setTimeout(() => {
        setShowCheck(false); // Hide the check
      }, 1700);
  if (!creating && token != '') {
    dispatch(fetchReservationCustomerMenu(token, 'no'));
    } else {
      dispatch(ResetReservation());
    }
    };


useEffect(() => {
  if (reservation_created && !from_restaurant) {
    const countryCodeLength = reservation_created.country_code.length;
    let phoneNumber = reservation_created.phone_number;
    // Remove leading plus if it exists
    if (phoneNumber[0] === '+') {
      phoneNumber = phoneNumber.slice(1);
    }
    // Remove leading country code number
    const phoneWithoutCountryCode = phoneNumber.slice(countryCodeLength);
    if (fullName === '') {
      setPhoneWithoutCountryCode(phoneWithoutCountryCode);
      setFullName(reservation_created.full_name);
      setEmail(reservation_created.email);
    }
  }
},[reservation_created]);

  const changeStep = (step) => {
    console.log('stepp: ', step);
    if (step === 0) {
      setCurrentStep(step);
      setStartDate(null);
      setTime(null);
      setNumPeople(0);
    }
    if (step === 1 && startDate != null) {
      setCurrentStep(step);
      setStartDateWS(false);
      setNumPeople(0);
      setTime(null);
    } else {
      setStartDateWS(true)
    }
    if (step === 2 && numPeople != '') {
      setCurrentStep(step);
    }
    if (step === 3 && time != '' && placeOfTable != '') {
      setCurrentStep(step);
      setTimeWS(false);
      setPlaceOfTableWS(false);
    } else {
      setTimeWS(true);
      setPlaceOfTableWS(true);
    }
  };

  const goBack = () => {
    if (currentStep === 3){
      setErrorMessage(false)
    }
    setCurrentStep(currentStep - 1);
  };

  const verifyEmptyFields = (verify_for_edition=false) => {
    console.log('email:', email)
    console.log('time:', time)
    console.log('fullName:', fullName)
    console.log('placeOfTable:', placeOfTable)
    console.log('phone:', phone)
    console.log('numPeople:', numPeople)
    let verifyFields = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email) && !from_restaurant){
      setTheEmailWS(true);
      verifyFields = false;
    } else {
      setTheEmailWS(false);
    }
    if (time === '' || null) {
      verifyFields = false;
      setTimeWS(true);
    } else {
      setTimeWS(false);
    }
    if ( fullName === '') {
      verifyFields = false;
      setFullNameWS(true);
    } else {
      setFullNameWS(false);
    }
    if ( placeOfTable === '') {
      verifyFields = false;
      setPlaceOfTableWS(true);
    } else {
      setPlaceOfTableWS(false);
    }
    console.log('from_restaurant isss : ', from_restaurant)
    if (isValidNumber('+' + phone) || from_restaurant) {
        console.log('from_restaurant isss : ', from_restaurant)
        setPhoneWS(false);
    } else {
        verifyFields = false;
        setPhoneWS(true);
    }

    if ( numPeople === 0) {
      verifyFields = false;
      setNumPeopleWS(true);
    } else {
      setNumPeopleWS(false);
    }
    
    if (!babyChairs) {
      setNumBabyChairs(0);
    }
    if (!verify_for_edition) {
      if ( acceptTerms === false && from_restaurant === false) {
        verifyFields = false;
        setAcceptTermsWS(true);
      } else {
        setAcceptTermsWS(false);
      }
    }
    /* if (from_restaurant == true) {
      verifyFields = true;
    } */
    return verifyFields;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let verifyFields = verifyEmptyFields();
    if (verifyFields === true) {
      setCurrentStep(currentStep + 1);
      if (creating === true && from_restaurant) {
        setCreating(false);
      }
      setShowCheck(true); // Show the check mark animation
      setTimeout(() => {
        setShowCheck(false); // Hide the check
      }, 1700);
      const fullTableInfo = getTableInfo(selectedTablesSave, tables);
      dispatch(fetchCreateReservation(restaurant_id, startDate, numPeople, 
                                      'Discontinued shift field', time, fullName, email, phone, countryCodeNumber, 
                                      babyChairs ? numBabyChairs : 0,  
                                      isMessageOpen ? message : '' , 
                                      placeOfTable, subscription,
                                      isAccessOpen ? 
                                      (accessability === 'Cadeira de Rodas' ? 'wheel_chair' : 'cane') 
                                      : 'not_needed', 
                                      from_restaurant, reservation_or_waiting_list));
    

    if (setIsEditModalOpen != false) {
      setIsEditModalOpen(false);

    }
    if (setTokens != false) {
      setTokens('');
    }
    
    }
  };

  const handleEdit = (e) => {
    let verifyFields = verifyEmptyFields(true);
    if (verifyFields === true) {
    toast('Reserva Editada', { autoClose: 1000, zIndex: 1000 });
    setShowCheck(true); // Show the check mark animation
      setTimeout(() => {
        setShowCheck(false); // Hide the check
      }, 1700);
    e.preventDefault();
    setCurrentStep(currentStep + 1);
    dispatch(fetchEditReservation(token, restaurant_id, startDate, 
                                  numPeople, 'Discontinued shift field', time, fullName, 
                                  phone, countryCodeNumber, babyChairs ? numBabyChairs : 0, 
                                  message, placeOfTable, subscription, 
                                  isAccessOpen ? 
                                  (accessability === 'Cadeira de Rodas' ? 'wheel_chair' : 'cane') 
                                  : 'not_needed',
                                  ignoreLimitations, from_restaurant));
  if (!creating && token != '') {
    dispatch(fetchReservationCustomerMenu(token, 'no'));
    } else {
      dispatch(ResetReservation());
    }
    setIsEditModalOpen(!isEditModalOpen);
    if (from_restaurant) {
      setTokens('');
    }
    }
  };


  useEffect(() => {
      dispatch(fetchOpenTime(restaurant_id, startDate, numPeople, token, ignoreLimitations));
  }, [dispatch, restaurant_id, startDate, numPeople, token, ignoreLimitations]);

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };
  const handlePlaceOfTable = (value) => {
    setTablesUsed([]);
    setPlaceOfTable(value);
  };
  const handlePeopleChange = (value) => {
    setNumPeople(value);
  };
  const handleBabyChange = (value) => {
    setNumBabyChairs(value);
  };

  const handleBabyReset = (value) => {
    setNumBabyChairs(0);
  };

  useEffect(() =>{
    if (reservation_created && currentStep >= 4 && reservation_created.token && !from_restaurant && createReservationFailed === false ) {
      navigate(`/customer_reservation_menu/${reservation_created.token}/await_confirmation`);
    }
  },[currentStep, reservation_created])

  const handleGoToReservation = (token) => {
    navigate(`/c/${token}/yes`);
  };
  const handleGoToReservationWhenEdited = () => {
    navigate(`/customer_reservation_menu/${editReservation.token}/await_confirmation`);
  };
  const handleGoBackToReservationsRestaurantMenu = () => {
    navigate(`/reservationfromrestaurant/${restaurant_id}`);
  };

  useEffect(() => {
    if (countryCodeNumber === '') {
      const detectedCountryCode = detectCountryCode();
      setCountryCode(detectedCountryCode);
      setCountryCodeNumber(getDialCode(detectedCountryCode));
    } else {
      setCountryCode(getCountryCode(countryCodeNumber));
    }
  }, [countryCodeNumber]);

  const detectCountryCode = () => {
    const userCountryCode = navigator.language.split("-")[1];
    const formattedCountryCode = userCountryCode ? userCountryCode.toLowerCase() : "us";
    // Add more specific checks if needed based on the origin or location detection mechanism
    return formattedCountryCode; // Default to 'PT' if the country code is not detected
  };

  const handleNotificationSubscription = (sub) => {
    setSubscription(sub);
  }

  useEffect(() => {
    // Check if countryCode and phone are not null or undefined before concatenating
    if (countryCodeNumber && phoneWithoutCountryCode.length >= 0) {
      setPhone(String(countryCodeNumber) + String(phoneWithoutCountryCode));
    }
  }, [phoneWithoutCountryCode, countryCodeNumber]);

  const handleTermsAndConditionsFalse = () => {
    setShowTermsAndConditions(false);
  };

  const MAX_CHARACTER_LIMIT = 10; 
  const handleInputPhoneChange = (e) => {
    const inputValue = e.target.value;
    console.log('phone input: ',inputValue)
    if (inputValue.length <= MAX_CHARACTER_LIMIT) {
      setPhoneWithoutCountryCode(inputValue);
    }
  };

  useEffect(() => {
    console.log('phone input true: ', phone)
  },[phone])

  const handleOpenDateModal = () => {
    setShowDateModal(true);
  };

  const handleCloseDateModal = () => {
    setShowDateModal(false);
  };

  useEffect(() => {
    if (openTime && Array.isArray(openTime.zones) && creating && from_restaurant) {
    let foundZoneWithoutF = false;
    let foundShiftWithoutF = false;



    if (!foundZoneWithoutF) {
        // Assuming your other state setter function is setOtherState
        setFullRestaurant(true);
    } else {
      setFullRestaurant(false);
    }
    }
}, [openTime, numPeople]);

  const handleExtras = (value) => {
    setIsExtrasOpen(!isExtrasOpen);
  };

  const handleTablesUsedChange = (value) => {
    setTablesUsed(value);
  };

  const handleCancel = () => {
    if (reservation) {
      dispatch(fetchCancelReservation(reservation.token));
    }
    setIsEditModalOpen(false);
    setShowModal(false);
    setTokens('');
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const changeDate = (date) => {
    setStartDate(date);
    setCurrentStep(1);
  };
  const chooseNumberOfPeople = (number_of_people) => {
    setNumPeople(number_of_people);
    setCurrentStep(2);
  };
  const changeTime = (time) => {
    setTime(time);
    setCurrentStep(3);
  };
  
  useEffect(() => {
    console.log('email: ', email)
    console.log('fullName: ', fullName)
  },[email, fullName])

  const all_tables = useSelector(state => state.restaurantTables.tables);

  return (
    <>
    { (creating || isEditModalOpen)  &&
    <>
    {showCheck &&
      <ContainerCheck>
        <ModalBackgroundCheck>
          <ToastContainer />
          <AnimatedCheck/>
        </ModalBackgroundCheck>
      </ContainerCheck>
      } 
  
        <>
        <Wrapper> 
        <h5>Esta a reservar no</h5>
        <Title>{restaurants ? restaurants.restaurant_name : <Loader></Loader>}</Title>
        <StepBar
          time={time}
          startDate={startDate}
          numPeople={numPeople}
          setNumPeople={setNumPeople}
          changeStep={changeStep}
          currentStep={currentStep}
          placeOfTable={placeOfTable}
          reservation_or_waiting_list={reservation_or_waiting_list}
          timezone={ restaurants && restaurants.timezone}
        />
        <StyledCard>
              {currentStep === 0 && (
                <>
                    <CustomDatePicker
                      selectedDate={startDate}
                      onDateChange={(date) => changeDate(date)}
                      id={restaurant_id}
                    />
                </>
              )}
  
              {currentStep === 1 && (
                <NumberOfPeopleSelector
                  numPeople={numPeople}
                  setNumPeople={chooseNumberOfPeople}
                />
              )}
  
              {currentStep === 2 && (
              <>
                <div style={{borderBottom:'1px solid black', paddingBottom:'10px', marginBottom:'10px', color:'black', width:'200px', height:'30px'}}>
                  <FontAwesomeIcon icon={faHouse} /> Selecionar sala
                </div>
                {numPeople !== 0 &&
                    <div style={{height:'50px'}}>
                        <CustomSelect
                            items={
                              reservation_or_waiting_list === 'waiting_list'
                                  ? (openTime.zones && ["Qualquer Sala", ...openTime.zones])
                                  : openTime.zones
                          }
                            onSelectChange={handlePlaceOfTable}
                            selectedItem={placeOfTable}
                            setUpdate={setUpdate}
                            update={update}
                        />
                        {/* {placeOfTableWS && <p style={{color: 'red'}}> Selecionar sala</p>} */}
                    </div>
                }
                {(reservation_or_waiting_list === 'reservation' && openTime)&&
                  <>
                  <div style={{borderBottom:'1px solid black', paddingBottom:'10px', color:'black', width:'200px', height:'30px'}}>
                  <FontAwesomeIcon icon={faClock} /> Selecionar Hora
                </div>
                
                  
                  <ChooseTime
                    options={openTime.opentime_per_zone} 
                    onTimeChange={handleTimeChange} 
                    time={time} 
                    setTime={setTime}
                    currentZone={placeOfTable}
                    editingStartFalse={editingStartFalse}
                    reservation={reservation}
                    update={update}
                    from_restaurant={from_restaurant}
                    true_create_false_edit={creating}
                    changeTime={changeTime}
                    people_per_time={openTime.people_per_time}
                  />
                  </>
                }
                
                
                
              </>
          )}  
  
            {currentStep === 3 && (
              <>
                 <StepThreeReservations
                  fullName={fullName}
                  setFullName={setFullName}
                  StyledPhoneInput={StyledPhoneInput}
                  countryCode={countryCode}
                  setCountryCodeNumber={setCountryCodeNumber}
                  InputOverlay={InputOverlay}
                  phoneWithoutCountryCode={phoneWithoutCountryCode}
                  handleInputPhoneChange={handleInputPhoneChange}
                  Extras={Extras}
                  isExtrasOpen={isExtrasOpen}
                  handleExtras={handleExtras}
                  ExtrasInside={ExtrasInside}
                  babyChairs={babyChairs}
                  DivFlexStart={DivFlexStart}
                  setBabyChairs={setBabyChairs}
                  DivFlexEnd={DivFlexEnd}
                  ScrollInputOptions={ScrollInputOptions}
                  numBabyChairs={numBabyChairs}
                  handleBabyChange={handleBabyChange}
                  limitPeopleBaby={limitPeopleBaby}
                  isMessageOpen={isMessageOpen}
                  setIsMessageOpen={setIsMessageOpen}
                  isAccessOpen={isAccessOpen}
                  message={message}
                  setMessage={setMessage}
                  maxMessageLength={maxMessageLength}
                  CustomSelect={CustomSelect}
                  accessabilities={accessabilities}
                  setIsAccessOpen={setIsAccessOpen}
                  setAccessability={setAccessability}
                  ActionButtons={ActionButtons}
                  goBack={goBack}
                  accessability={accessability}
                  setShowTermsAndConditions={setShowTermsAndConditions}
                  handleSubmit={handleSubmit}
                  handleEdit={handleEdit}
                  phoneWS={phoneWS}
                  setEmail={setEmail}
                  email={email}
                  emailWS={emailWS}
                  fullNameWS={fullNameWS}
                  true_create_false_edit={creating}
                  acceptTerms={acceptTerms}
                  setAcceptTerms={setAcceptTerms}
                  acceptTermsWS={acceptTermsWS}
                  from_restaurant={from_restaurant}
                  creating={creating}
              />
              <div style={{position:'absolute', left:'30px', bottom:'100px', width:'70%'}}>
              {numPeople !== 0 && 
                  <>
                      {((token != '' && from_restaurant && Array.isArray(tablesUsed)) || ignoreLimitations)  && 
                      <Col xs={12}  className='mt-3 d-flex' style={{gap: '3px', flexWrap:'wrap'}}>
                          {ignoreLimitations ? 'Escolher mesas: ' : 'Mesas: '} 
                          <>
                          {(Array.isArray(tablesUsed) && tablesUsed[0] != '' && tablesUsed.length > 0) ?
                              <>
                                  {tablesUsed
                                      .map(tableNumber => parseInt(tableNumber, 10))  // Convert to integers
                                      .sort((a, b) => a - b)  // Sort in ascending order
                                      .map((tableNumber, index) => {
                                          const tableObj = all_tables.find(table => table.id === tableNumber);
                                          return (
                                              <TableBox key={index} tableNumber={tableNumber}>
                                                  {tableObj ? tableObj.table_name : `Table ${tableNumber} not found`}
                                              </TableBox>
                                          );
                                  })}
                              </>
                          :
                              <div style={{color: 'red'}}>
                                  Não tem mesas selecionadas
                              </div>
                          }
                          </>
                          <FontAwesomeIcon size='xl' icon={faFilePen} style={{color: "gray", cursor: 'pointer'}} onClick={handleShowTablesModal}/>
                      </Col>
                      }
                  </>
              }
              </div>
            </>
          )}
          
                
          {currentStep === 4 && (
            <>
              {isLoading ? 
                <h2><Loader></Loader></h2>
              :error && error.includes('reservation-exists') ? 
              <>
                <h2>
                  <FontAwesomeIcon size='xl' icon={faTriangleExclamation} style={{color: "#f3e335", marginRight: '15px'}} />
                  Já tem um pedido de reserva activo para este dia.
                </h2>
                <h5>Pode alterar a reserva existente no seguinte botão:</h5>
  
                <Button className='mt-3' onMouseDown={() => handleGoToReservation(error.split(';')[1])} >
                  Aceder reserva 
                  <FontAwesomeIcon icon={faShareFromSquare} style={{color: "#ffffff", marginLeft:'5px'}} />
                </Button>
              </>
                
              :
              <h2>Por favor atualizar a página.</h2>
              } 
              </>
          )}
        </StyledCard>
        
        {/*<PushNotificationForReservation
          setSubscription={handleNotificationSubscription}
        />*/}
        
        
        
  
        <TermsAndConditions
          showTermsAndConditions={showTermsAndConditions}
          setSetShowTermsAndConditions={handleTermsAndConditionsFalse}
        />
      </Wrapper>
        
        </>
  
  
      {showDateModal &&
        <ModalBackgroundToCustomDate>
          <CustomDateContainer onHide={handleCloseDateModal}>
            <CustomDatePicker
                  selectedDate={startDate}
                  onDateChange={(date) => changeDate(date)}
                  id={restaurant_id}
                  handleCloseDateModal={handleCloseDateModal}
              />
            </CustomDateContainer>
        </ModalBackgroundToCustomDate>
      }
  
  
      { tables &&
        <TablesModal
        show={showTablesModal}
        tables_list={tables}
        onClose={() => setShowTablesModal(false)}
        onSave={handleModalSave}
        initialSelectedTables={usedTablesDic}
        token={token}
        id={restaurant_id}
        numPeople={numPeople}
        handleTablesUsedChange={handleTablesUsedChange}
        setSelectedTablesSave={(value) => setSelectedTablesSave(value)}
        setPlaceOfTable={(value) => setPlaceOfTable(value)}
      />
      }
      <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Deseja cancelar a sua reserva?</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                {isLoadingCancel?
                  <Loader></Loader>:
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancelar
                  </Button>
                }{/* 
                  <Button variant="danger" onClick={handleCloseModal}>
                    Não
                  </Button> */}
              </Modal.Footer>
            </Modal>
    
    </>
    
    
    }
    
    </>  
  );
};

export default ReservationScreen;

