import { Container } from 'react-bootstrap'
import Counter from './features/counter/Counter'
import  axios  from 'axios'
import Header from './components/Header'
import Footer from './components/Footer'
import NotificationPage from './components/Push_notification'
import { Routes, Route, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import EditReservationScreen from './screens/EditReservationScreen';
import ReservationCustomersMenuScreen from './screens/ReservationCustomersMenuScreen';
import ReservationFromRestaurantScreen from './screens/ReservationFromRestaurantScreen';
import ReservationFromRestaurantDetails from './screens/ReservationFromRestaurantDetails';
import ReservationScreen from './screens/ReservationScreen';
import ProfileScreen from './screens/ProfileScreen';
import CreateAccountScreen from './screens/CreateAccountScreen';
import HomeScreen from './screens/HomeScreen';
import LoginScreen  from './screens/LoginScreen'
import RestaurantFromOwnerScreen from './screens/RestaurantFromOwnerScreen'
import RestaurantSettingsUpdate from './screens/RestaurantSettingsUpdate'
import RestaurantZonesTablesManagement from './screens/RestaurantZonesTablesManagement'
import RestaurantTimeManagement from './screens/RestaurantTimeManagement'
import RestaurantCreation from './screens/RestaurantCreation'
import RestaurantClients from './screens/RestaurantClients'
import ManagersCreationScreen from './screens/ManagersCreationScreen'
import PlaceDesignScreen from './screens/PlaceDesignScreen'
import RestaurantConnectedTables from './screens/RestaurantConnectedTables'
import RestaurantOpenedOrLimitedExceptions from './screens/RestaurantOpenedOrLimitedExceptions'
import RestaurantEstimatedTimes from './screens/RestaurantEstimatedTimes'
import ConfirmNotification from './screens/ConfirmNotification'
import RestaurantNotifications from './screens/RestaurantNotifications'
import AccountRecovery from './screens/AccountRecovery'
import PasswordRecoveryChange from './screens/PasswordRecoveryChange'
import FiscalAdress from './screens/FiscalAdress'
import ChoosePlanAndPay from './screens/ChoosePlanAndPay'
import DeleteClientInfo from './screens/DeleteClientInfo'
import RestaurantInfoUpdate from './screens/RestaurantInfoUpdate'
import Time from './screens/Time'

import {
  fetchReservationActive 
} from './actions/reservationsActions';

export const api = axios.create({
  baseURL: '/api',
  headers: {
      'Content-Type': 'application/json'
  },
})


function App() {
  let location = useLocation();
  const id = useParams()

  const isReservationActive = useSelector((state) => state.reservationActive);
  const { isActive } = isReservationActive;
  return (
    <div className="App">
       {isActive ? null : (<Header/>)}
       {/* {isActive ? null : ()} <NotificationPage/> */}
      <main>
      <div>
         <Routes>
          <Route path='/login/:username/:password' element={<LoginScreen/>} />
          <Route path='/reservationfromrestaurant/:restaurant_id/:date/:status' element={<ReservationFromRestaurantScreen/>} />
          <Route path='/delete_client_info/:token/:phone_number/' element={<DeleteClientInfo/>} />
          <Route path='/c/:token/:change_status' element={<ReservationCustomersMenuScreen/>} />
          <Route path='/customer_reservation_menu/:token/:change_status' element={<ReservationCustomersMenuScreen/>} />
          <Route path='/editreservation/:token' element={<EditReservationScreen/>} />
          <Route path='/reservationfromrestaurantdetails/:reservation_id' element={<ReservationFromRestaurantDetails/>} />
          <Route path='/reservations/:restaurant_id/:from_whom/:token' element={<ReservationScreen/>} />
          <Route path='/restaurantfromowner/:id' element={<RestaurantFromOwnerScreen />} />
          <Route path='/RestaurantZonesTablesManagement/:id' element={<RestaurantZonesTablesManagement />} />
          <Route path='/RestaurantTimeManagement/:id' element={<RestaurantTimeManagement />} />
          <Route path='/time/:id' element={<Time />} />
          <Route path='/restaurantclients/:id' element={<RestaurantClients />} />
          <Route path='/restaurant_creation/:tutorial/:step/:restaurant_id' element={<RestaurantCreation />} />
          <Route path='/yo'  element={<Counter />} />
          <Route path='/profile/update/:first_time_manager_login'  element={<ProfileScreen />} />
          <Route path='/register' element={<CreateAccountScreen />} />
          <Route path='/manager_account_creation' element={<ManagersCreationScreen />} />
          <Route path='/place_design/:restaurant_id' element={<PlaceDesignScreen />} />
          <Route path='/restaurant_connected_tables/:restaurant_id' element={<RestaurantConnectedTables />} />
          <Route path='/RestaurantOpenedOrLimitedExceptions/:restaurant_id' element={<RestaurantOpenedOrLimitedExceptions />} />
          <Route path='/RestaurantEstimatedTimes/:restaurant_id' element={<RestaurantEstimatedTimes />} />
          <Route path='/notifications/:token/:email_phone' element={<ConfirmNotification />} />
          <Route path='/restaurant_notifications/:restaurant_id' element={<RestaurantNotifications />} />
          <Route path='/passwordRecovery' element={<AccountRecovery />} />FiscalAdress
          <Route path='/recovery_code/:recovery_code' element={<PasswordRecoveryChange />} />
          <Route path='/FiscalAddress' element={<FiscalAdress />} />
          <Route path='/choose_plan_and_pay/:restaurant_id' element={<ChoosePlanAndPay />} />
          <Route path='/restaurant_info_update/:id' element={<RestaurantInfoUpdate />} />
          <Route path='/restaurantsettingsupdate/:id' element={<RestaurantSettingsUpdate />} />
          <Route path='/' element={<HomeScreen />} />
         </Routes>
      </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;

