import React, { useEffect, useRef } from 'react';
import FormContainer from '../components/FormContainer';
import { Row, Col, Card, Container, Navbar, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { fetchReservationActive } from '../actions/reservationsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

const FullHeightRow = styled(Row)`
  height: 100%;
  margin: 0;
  padding: 0;
`;

const LeftSection = styled(Col)`
  background: linear-gradient(225deg, #ffe9db 0%, #ffffff 100%);
  background-size: cover;
  background-position: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const SlideshowWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
`;

const RightSection = styled(Col)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: #ffffff;
`;

const LogoImage = styled.img`
  max-width: 100px;
  height: auto;

  @media (max-width: 768px) {
    display: block;
  }
`;

const SobreNosCard = styled(Container)`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  text-align: start;
`;

const Ptext = styled.h4`
  font-style: bold;
  font-size: 20px;
`;

const NavbarCustom = styled(Navbar)`
  background-color: #ffffff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const LoginButton = styled(Button)`
  background-color: #f59878;
  border: none;
  margin-left: auto; /* Push the button to the right */
  &:hover {
    background-color: #f07c54;
  }
`;

const LoginScreen = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);  // Reference to the login section

  useEffect(() => {
    dispatch(fetchReservationActive());
  }, [dispatch]);

  // Check if screen width is less than 768px
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  // Scroll to the form section when the login button is clicked
  const handleLoginClick = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      {/* Responsive Navbar - Only shows on small screens */}
      {isSmallScreen && (
        <NavbarCustom expand="lg">
          <Navbar.Brand href="#" style={{ fontWeight: 'bold' }}>
            <LogoImage
              src="https://lh3.googleusercontent.com/pw/AP1GczP6Bx9SgtsCwzOaJve-VNUT_jUk-cARh0t8rBgrRNfnfZlKrpTnpsW95WBW3OIN-bDBLan1HwY6f1UryAJmasz_97HES7bO4w_7gw112qDvU_gJiDx8LwSzaUH3Ew7LOtw9wNSrCYuOBfjcbwHtDp4=w1868-h952-s-no-gm?authuser=0"
              alt="Company Logo"
            />
          </Navbar.Brand>
          <LoginButton onClick={handleLoginClick}>
            <FontAwesomeIcon icon={faUser} /> Login
          </LoginButton>
        </NavbarCustom>
      )}

      <FullHeightRow>
        {/* Left Section with Logo Image */}
        <LeftSection md={7} xs={12}>
          <SlideshowWrapper>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100%', marginBottom: '40px' }}
            >
              <SobreNosCard>
                <Card.Body>
                  <h2 className="slide-title" style={{ marginBottom: '20px' }}>
                    Aplicação de reservas para qualquer restaurante
                  </h2>
                  <Ptext>
                    <FontAwesomeIcon icon={faCheck} style={{ color: '#a3a7ae' }} /> Aceita reservas automaticamente.
                  </Ptext>
                  <Ptext>
                    <FontAwesomeIcon icon={faCheck} style={{ color: '#a3a7ae' }} /> Otimização de reservas recebidas.
                  </Ptext>
                  <Ptext>
                    <FontAwesomeIcon icon={faCheck} style={{ color: '#a3a7ae' }} /> Prevenção contra no shows.
                  </Ptext>
                  <Ptext>
                    <FontAwesomeIcon icon={faCheck} style={{ color: '#a3a7ae' }} /> Implementação de notificações via
                    whatsapp.
                  </Ptext>
                </Card.Body>
              </SobreNosCard>
            </div>
          </SlideshowWrapper>
        </LeftSection>

        {/* Right Section with Form */}
        <RightSection xs={12} md={5} ref={formRef}>
          {/* Use ref here */}
          <ContentWrapper>
            <img
              src="https://lh3.googleusercontent.com/pw/AP1GczP6Bx9SgtsCwzOaJve-VNUT_jUk-cARh0t8rBgrRNfnfZlKrpTnpsW95WBW3OIN-bDBLan1HwY6f1UryAJmasz_97HES7bO4w_7gw112qDvU_gJiDx8LwSzaUH3Ew7LOtw9wNSrCYuOBfjcbwHtDp4=w1868-h952-s-no-gm?authuser=0"
              alt="Company Logo"
              style={{ maxWidth: '220px', height: 'auto' }}
            />
            <FormContainer />
          </ContentWrapper>
        </RightSection>
      </FullHeightRow>
    </div>
  );
};

export default LoginScreen;
