import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const OptionsBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 300px;
  align-items: center;
  width: 100%;
  background-color: white;
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;
  align-items: center;
  width: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 4px;
    &:hover {
      background: #909090;
    }
  }

  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 transparent;
`;

const OptionBox = styled.div`
  display: flex;
  color: black;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90px;
  height: 60px;
  margin: 10px;
  position: relative;
  background-color: #f8f8f8;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const Indicator = styled.div`
  height: 4px;
  width: 80%;
  position: absolute;
  bottom: 0;
  background-color: ${({ isSelected }) => (isSelected ? '#41ab8b' : 'transparent')};
  transition: background-color 0.3s ease;
`;

const InnerLine = styled.div`
  width: 30%;
  height: 4px;
  border-radius: 20%;
  position: absolute;
  bottom: 15px;
  background-color: ${({ isFull, surpasses_people_limit, from_restaurant }) =>
    from_restaurant && surpasses_people_limit
      ? '#d9c834'
      : isFull || surpasses_people_limit
      ? '#c46127'
      : '#41ab8b'};
`;

function ChooseTime({
  options,
  onTimeChange,
  time,
  changeTime,
  currentZone,
  from_restaurant,
  people_per_time,
}) {
  const [selectedTime, setSelectedTime] = useState(time || null);
  const [currentOptions, setCurrentOptions] = useState('');

  const [showModalWarning, setShowModalWarning] = useState(false); // Tracks the visibility of the modal warning
  const [fullyBooked, setFullyBooked] = useState(false);
  const [sanitizedTimeUseState, setSanitizedTimeUseState] = useState(null);

  const selectedTimeRef = useRef(null);

  useEffect(() => {
    if (time !== selectedTime) {
      setSelectedTime(time);
    }
  }, [time]);

  useEffect(() => {
    if (options && currentZone) {
      const timesInCurrentZone = options[currentZone] || [];
      setCurrentOptions(timesInCurrentZone);
    }
  }, [options, currentZone]);

  const handleTimeClick = (event, time, index) => {
    event.preventDefault();

    let sanitizedTime = time;
    if (time.endsWith('/f') || time.endsWith('/b')) {
      sanitizedTime = time.slice(0, -2);
    }

    setSanitizedTimeUseState(sanitizedTime);

    if (!from_restaurant && (time.endsWith('/f') || time.endsWith('/b'))) {
      return;
    } else if (from_restaurant && time.endsWith('/f')) {
      setFullyBooked(true); // Set fully booked modal
      setShowModalWarning(true);
    } else if (from_restaurant && time.endsWith('/b')) {
      setFullyBooked(false); // Set people limit modal
      setShowModalWarning(true);
    } else {
      setSelectedTime(sanitizedTime);
      changeTime(sanitizedTime);
      onTimeChange(sanitizedTime);
    }
  };

  const handleConfirmWarning = () => {
    setSelectedTime(sanitizedTimeUseState);
    changeTime(sanitizedTimeUseState);
    onTimeChange(sanitizedTimeUseState);
  };

  return (
    <OptionsBox>
      {Array.isArray(currentOptions) && currentOptions.length > 0 ? (
        <OptionsWrapper>
          {currentOptions.map((originalTime, index) => {
            const isFull = originalTime.endsWith('/f');
            const surpasses_people_limit = originalTime.endsWith('/b');
            const sanitizedTime = (isFull || surpasses_people_limit)
              ? originalTime.slice(0, -2)
              : originalTime;
            const isSelected = selectedTime === originalTime;
            const reservationCount = (people_per_time && people_per_time[sanitizedTime]) || 0;

            return (
              <OptionBox
                key={index}
                ref={isSelected ? selectedTimeRef : null}
                onClick={(event) => handleTimeClick(event, originalTime, index)}
              >
                <div>{sanitizedTime}</div>
                {(reservationCount > 0 && from_restaurant) && <div style={{fontSize:'10px', position:'absolute', bottom:'0px'}}>({reservationCount})</div>}
                <InnerLine
                  isFull={isFull}
                  surpasses_people_limit={surpasses_people_limit}
                  from_restaurant={from_restaurant}
                />
              </OptionBox>
            );
          })}
        </OptionsWrapper>
      ) : (
        <div>Selecionar sala</div>
      )}
      <Modal show={showModalWarning} onHide={() => setShowModalWarning(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Aviso{' '}
            <FontAwesomeIcon
              size="xl"
              icon={faTriangleExclamation}
              style={{ color: '#f3e335', marginRight: '15px' }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fullyBooked ? (
            <div style={{ color: 'red' }}>
              O restaurante encontra-se lotado neste horário e se pretender
              continuar não será possivel alocar mesas.
            </div>
          ) : (
            <div style={{ color: '#d9c834' }}>
              O limite de pessoas por horário vai ser ultrapassado.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleConfirmWarning}>
            Continuar à mesma
          </Button>
          <Button variant="danger" onClick={() => setShowModalWarning(false)}>
            Não continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </OptionsBox>
  );
}

export default ChooseTime;
