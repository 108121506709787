import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClientInfo } from '../actions/userActions';
import { fetchReservationActive } from '../actions/reservationsActions';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const DeleteClientInfoPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
`;

const ConfirmationBox = styled.div`
  background: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
`;

const Button = styled.button`
  background-color: ${(props) => (props.danger ? '#D9534F' : '#5cb85c')};
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  margin: 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.danger ? '#c9302c' : '#4cae4c')};
  }
`;

const Message = styled.p`
  font-size: 18px;
  color: ${(props) => (props.error ? '#D9534F' : '#5cb85c')};
  margin-top: 20px;
`;

function DeleteClientInfoPage() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();

  dispatch(fetchReservationActive());

  const { isLoadingDeleteClientInfo, deleteClientInfo: deleteResponse, errorDeleteClientInfo } = useSelector(state => state.restaurant_clients);

  const { token, phone_number } = useParams();

  const handleDelete = () => {
    dispatch(deleteClientInfo(token, phone_number));
  };

  return (
    <DeleteClientInfoPageWrapper>
      {!confirmDelete ? (
        <ConfirmationBox>
          <Title>Apagar dados de cliente?</Title>
          <Button onClick={() => setConfirmDelete(true)}>Sim, por favor</Button>
          <Button onClick={() => setConfirmDelete(false)}>Não</Button>
        </ConfirmationBox>
      ) : (
        <ConfirmationBox>
          {isLoadingDeleteClientInfo ? (
            <Message>Apagando dados...</Message>
          ) : deleteResponse ? (
            <Message>{deleteResponse.success}</Message>
          ) : errorDeleteClientInfo ? (
            <Message error>{errorDeleteClientInfo}</Message>
          ) : (
            <Button danger onClick={handleDelete}>Confirmar</Button>
          )}
        </ConfirmationBox>
      )}
    </DeleteClientInfoPageWrapper>
  );
}

export default DeleteClientInfoPage;
