import { api } from '../App'
import {userUpdateReducerSlice ,userLoginReducerSlice, userRegisterReducerSlice, fetchUserSubscriptionSlice,
        storeUserSubscriptionSlice, getRestaurantSubscriptionSlice, getRestaurantClientsSlice, getSubscriptionPackagesSlice,
        fiscalAdressCrudSlice, whatsappSubscriptionsSlice } from '../reducers/userReducers'
import { getManager } from '../actions/managersActions'

const { userLoginRequest, userLoginSuccess, userLoginFail, userLogout } = userLoginReducerSlice.actions
  
export const login = (email, password) => async dispatch => {
    
    try {
        dispatch(userLoginRequest())

        const config  = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        console.log('email to lower case', email.toLowerCase())
        const response = await api.post(
            '/users/login/',
            { 'username': email.toLowerCase(), 'password': password },
            config
        )
        localStorage.setItem("userInfo", JSON.stringify(response.data))
        const login_info = { 'username': email, 'password': password }
        localStorage.setItem("login_info", JSON.stringify(null),[null])
        localStorage.setItem("login_info", JSON.stringify(login_info))
        dispatch(userLoginSuccess(response.data))
        

        // Assuming that response.data is an object that contains id
        const user_id = response.data.id;
        dispatch(getSubscription(user_id));
        dispatch(getManager());
        dispatch(getRestaurantSubscription());
    }
    catch (error) {
        dispatch(
            userLoginFail(
                error.response && error.response.data.detail 
                ? error.response.data.detail
                : error.message
            )
        );
    }
}
const { fetchUserSubscriptionRequest, fetchUserSubscriptionSuccess, fetchUserSubscriptionFail, userSubscriptionReset } = fetchUserSubscriptionSlice.actions

export const getSubscription = (user_pk) => async dispatch => {
    
    try {
        dispatch(fetchUserSubscriptionRequest(),)


        const  response  = await api.get(`/users/get/subscription/${user_pk}/`,
        )
        dispatch(fetchUserSubscriptionSuccess(response.data))
        localStorage.setItem("userSubscription", JSON.stringify(response.data))
    }
    catch (error) {
      dispatch(fetchUserSubscriptionFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { storeUserSubscriptionRequest, storeUserSubscriptionSuccess, storeUserSubscriptionFail } = storeUserSubscriptionSlice.actions

export const storeSubscription = (user_pk, subscription) => async dispatch => {
    
    try {
        dispatch(storeUserSubscriptionRequest(),)


        const  data  = await api.post('/users/update_push_notification_subscription/',
        {'user_pk':user_pk, 'subscription':subscription},
        )
        .then((response) => dispatch(storeUserSubscriptionSuccess()))
        dispatch(getSubscription(user_pk));
    }
    catch (error) {
      dispatch(storeUserSubscriptionFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

export const logout = () => async dispatch => {
    
    try {
        dispatch(userLogout(),userSubscriptionReset())
        localStorage.setItem("userInfo", JSON.stringify(null),[null])
        localStorage.setItem("manager_info", JSON.stringify(null),[null])
    }
    catch (error) {
      dispatch(userLoginFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { userRegisterRequest, userRegisterSuccess, userRegisterFail, username_exists, username_not_exists } = userRegisterReducerSlice.actions

export const register = (name, email, password) => async dispatch => {
    
    try {
        dispatch(userRegisterRequest(),)

        const config  = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const  data  = await api.post('/users/register/',
        { 'name':name ,'email':email, 'password':password},
        config
        )
        .then((response) => dispatch(userLoginSuccess(response.data),getSubscription(response.data.id)))
        dispatch(userRegisterSuccess())

        localStorage.setItem("userInfo", JSON.stringify(data.payload),[data.payload])
        dispatch(getManager());
        dispatch(getRestaurantSubscription());
    }
    catch (error) {
      dispatch(userRegisterFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

export const verify_username = (username) => async dispatch => {
    
    try {
        console.log('username: ', username)
        const  data  = await api.post('/users/check_for_same_user/',
        {'username':username},
        )
        dispatch(username_exists());
    }
    catch (error) {
      dispatch(username_not_exists());
    }
}

const { userUpdateReset ,
        userUpdateRequest, 
        userUpdateSuccess, 
        userUpdateFail,
        passwordRecoveryRequest,
        passwordRecoverySuccess,
        passwordRecoveryFail,
        verifyPasswordRecoveryRequest,
        verifyPasswordRecoverySuccess,
        verifyPasswordRecoveryFail,
        changePasswordRecoveryRequest,
        changePasswordRecoverySuccess,
        changePasswordRecoveryFail } = userUpdateReducerSlice.actions

export const updateUser = (user) => async (dispatch, getState) => {
    try {
        dispatch(userUpdateRequest(),)
        const {
            userLogin: { userInfo }
        } = getState()
        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }

        const  data  = await api.put(
            `/users/profile/update/`,
            user,
            config
            )
        .then((response) => dispatch(userLoginSuccess(response.data)))
        dispatch(userUpdateSuccess())
        localStorage.setItem("userInfo", JSON.stringify(data),[data])
    }
    catch (error) {
      dispatch(passwordRecoveryFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

export const sendPasswordRecoveryEmail = (email) => async (dispatch, getState) => {
    try {

        dispatch(passwordRecoveryRequest(),)
        const  data  = await api.post(
            `/users/send_password_recovery_email/`, {'email':email}
            )
        .then((response) => dispatch(passwordRecoverySuccess(response.data.detail)))
    }
    
    catch (error) {
      dispatch(passwordRecoveryFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}


export const verifyPasswordRecoveryCode = (recovery_code) => async (dispatch, getState) => {
    try {

        dispatch(verifyPasswordRecoveryRequest(),)

        const  data  = await api.post(
            `/users/verify_password_recovery_code/`, {'recovery_code':recovery_code}
            )
        .then((response) => dispatch(verifyPasswordRecoverySuccess(response.data.detail)))
    }
    catch (error) {
      dispatch(verifyPasswordRecoveryFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}
export const actionChangePasswordRecovery = (recovery_code ,password) => async (dispatch, getState) => {
    try {
        dispatch(changePasswordRecoveryRequest(),)
        const  data  = await api.post(
            `/users/change_password_recovery/`, {'recovery_code':recovery_code, 'password':password}
            )
        .then((response) => dispatch(changePasswordRecoverySuccess(response.data.detail)))
    }
    catch (error) {
      dispatch(changePasswordRecoveryFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}



export const resetUpdate = () => async dispatch => {
    
    try {
        dispatch(userUpdateReset(),)
    }
    catch (error) {
      dispatch(userLoginFail(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}

const { GetRestaurantSubscriptionRequest ,GetRestaurantSubscriptionSuccess, getRestaurantSubscriptionError } = getRestaurantSubscriptionSlice.actions

export const getRestaurantSubscription = () => async (dispatch, getState) => {
    
    try {

        const {
            userLogin: { userInfo }
        } = getState()

        dispatch(GetRestaurantSubscriptionRequest(),)
        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const  data  = await api.get(
            `/users/get/restaurant_subscription/`,
            config
            )
        .then((response) => dispatch(GetRestaurantSubscriptionSuccess(response.data)))
 
    }
    catch (error) {
      dispatch(getRestaurantSubscriptionError(error.response && error.response.data.detail 
                                    ? error.response.data.detail
                                    : error.message));
    }
}


const { GetRestaurantClientsRequest, 
        GetRestaurantClientsSuccess, 
        getRestaurantClientsError,
        GetRestaurantInfoClientRequest,
        GetRestaurantInfoClientSuccess,
        GetRestaurantInfoClientError
    } = getRestaurantClientsSlice.actions

export const getRestaurantClients = (restaurant_id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()

        dispatch(GetRestaurantClientsRequest(),)
        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const  data  = await api.get(
            `/users/restaurant_clients/${restaurant_id}/`,
            config
            )
        .then((response) => dispatch(GetRestaurantClientsSuccess(response.data)))
 
    }
    catch (error) {
      dispatch(getRestaurantClientsError(error.response && error.response.data.detail
                                        ? error.response.data.detail
                                        : error.message));
    }
}

export const getRestaurantInfoClient = (phone_number, restaurant_id) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()

        dispatch(GetRestaurantInfoClientRequest(),)

        const params = {
            phone_number: phone_number,
            restaurant_id: restaurant_id
        };

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
            params: params
        }
        const  data  = await api.get(
            `/users/get_clients_info/`, config
            )
        .then((response) => dispatch(GetRestaurantInfoClientSuccess(response.data)))
 
    }
    catch (error) {
      dispatch(GetRestaurantInfoClientError(error.response && error.response.data.detail 
                                        ? error.response.data.detail
                                        : error.message));
    }
}

// --------------------- Fiscal address -----------------------

export const {
    createFiscalAdressStart,
    createFiscalAdressSuccess,
    createFiscalAdressError,
  
    getFiscalAdressStart,
    getFiscalAdressSuccess,
    getFiscalAdressError,
  
    updateFiscalAdressStart,
    updateFiscalAdressSuccess,
    updateFiscalAdressError,
  
    deleteFiscalAdressStart,
    deleteFiscalAdressSuccess,
    deleteFiscalAdressError,
  } = fiscalAdressCrudSlice.actions;
  
  export const createFiscalAddress = (user_id, params) => async (dispatch) => {
    try {
      console.log('params: ', params)
      dispatch(createFiscalAdressStart());
  
      // Adjust the API endpoint and payload as needed
      await api.post(`/users/fiscal-information-cr/${user_id}/`, { ...params, user_id })
        .then((response) => dispatch(createFiscalAdressSuccess(response.data)));
      dispatch(getFiscalAddressList(user_id)); // You may need to implement this
    } catch (error) {
      dispatch(createFiscalAdressError(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
  };
  
  export const getFiscalAddressList = (user_id) => async (dispatch) => {
    try {
      dispatch(getFiscalAdressStart());
  
      // Adjust the API endpoint as needed
      await api.get(`/users/fiscal-information-cr/${user_id}/`)
        .then((response) => dispatch(getFiscalAdressSuccess(response.data)));
    } catch (error) {
      dispatch(getFiscalAdressError(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
  };
  
  export const updateFiscalAddress = (id, params) => async (dispatch) => {
    try {
      dispatch(updateFiscalAdressStart());
  
      // Adjust the API endpoint and payload as needed
      await api.patch(`/users/fiscal-information-ud/${id}/`, params)
        .then((response) => dispatch(updateFiscalAdressSuccess(response.data)));
    } catch (error) {
      dispatch(updateFiscalAdressError(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
  };
  
  export const deleteFiscalAddress = (id) => async (dispatch) => {
    try {
      dispatch(deleteFiscalAdressStart());
  
      // Adjust the API endpoint as needed
      await api.delete(`/users/fiscal-information-ud/${id}/`)
        .then((response) => dispatch(deleteFiscalAdressSuccess(response.data)));
    } catch (error) {
      dispatch(deleteFiscalAdressError(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
  };
  // ----------------- getSubscriptions -----------------
  
  export const {
    getSubscriptionPackagesStart,
    getSubscriptionPackagesSuccess,
    getSubscriptionPackagesError,
  } = getSubscriptionPackagesSlice.actions;
  
  export const fetchSubscriptionPackages = () => async (dispatch) => {
    try {
      dispatch(getSubscriptionPackagesStart());
  
      // Adjust the API endpoint as needed
      const response = await api.get('/users/subscription-packages/');
      dispatch(getSubscriptionPackagesSuccess(response.data));
    } catch (error) {
      dispatch(getSubscriptionPackagesError(error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message));
    }
  };

  export const {
    getWhatsappSubscriptionsRequest,
    getWhatsappSubscriptionsSuccess,
    getWhatsappSubscriptionsError,

  } = whatsappSubscriptionsSlice.actions;
  
export const getWhatsappSubscriptionsAction = () => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()

        dispatch(getWhatsappSubscriptionsRequest(),)

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const  data  = await api.get(
            `/get_whatsapp_subscriptions/`, config
            )
        .then((response) => dispatch(getWhatsappSubscriptionsSuccess(response.data)))
 
    }
    catch (error) {
      dispatch(getWhatsappSubscriptionsError(error.response && error.response.data.detail 
                                        ? error.response.data.detail
                                        : error.message));
    }
}

export const {

    createWhatsappSubscriptionRequest,
    createWhatsappSubscriptionSuccess,
    createWhatsappSubscriptionError,

  } = whatsappSubscriptionsSlice.actions;

export const createWhatsappSubscriptionAction = (params) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()

        dispatch(createWhatsappSubscriptionRequest(),)

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const  data  = await api.post(
            `/create_whatsapp_subscription/`, params, config
            )
        .then((response) => dispatch(createWhatsappSubscriptionSuccess(response.data)))
 
    }
    catch (error) {
      dispatch(createWhatsappSubscriptionError(error.response && error.response.data.detail 
                                        ? error.response.data.detail
                                        : error.message));
    }
}


export const {

    DeleteClientInfoRequest,
    DeleteClientInfoSuccess,
    DeleteClientInfoError,

  } = getRestaurantClientsSlice.actions;

export const deleteClientInfo = (token, phone_number) => async (dispatch, getState) => {
    try {
        const {
            userLogin: { userInfo }
        } = getState()

        dispatch(DeleteClientInfoRequest(),)

        const config  = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`,
            },
        }
        const  data  = await api.post(`/users/delete_client_info/${token}/${phone_number}/`, config)
        .then((response) => dispatch(DeleteClientInfoSuccess(response.data)),)
    }
    catch (error) {
      dispatch(DeleteClientInfoError('Dados de cliente não foram encontrados'));
    }
}


