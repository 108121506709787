import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CustomSelectArray from '../components/CustomSelectArray';
import axios from 'axios';
import Loader from './Loader';
import { api } from '../App';
import {
  fetchClosedExceptions,
  createClosedExceptions,
  fetchPlaceOfTable,
} from '../actions/restaurantActions';
import styled from 'styled-components';
import DropDownButton from '../components/DropDownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCalendar, faMapLocation, faCircleXmark, faClock, faUser, faSun } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top:10px;
  padding: 20px;
  background-color: #F0EBE0;
  border-radius: 10px;
`;

const StyledButton = styled.button`
  padding: 10px 15px;
  background-color: #75726f;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #474645;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #EE754C;
  color: white;
  border-radius: 10px 10px 0 0;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  width: auto;
  margin: 0 auto;
  border-radius: 10px;
  .rdrDefinedRangesWrapper {
    display: none;
  }
`;

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
  color: white;
`;

const HorizontalItemBox = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;
`;

const ActionButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #EE754C;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: #4B3335;
  }
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 15px;
`;

const SubmitButton = styled(StyledButton)`
  background-color: #9FBBC1;
  border: none;

  &:hover {
    background-color: #4B3335;
  }
`;

const ExceptionsList = styled.div`
  max-height: 300px;
  height:150px;
  overflow-y: auto;
  margin-top: 20px;
  padding-right: 10px;
`;

const ClosedExceptions = ({ restaurant_id }) => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (restaurant_id) {
      dispatch(fetchClosedExceptions(restaurant_id));
      dispatch(fetchPlaceOfTable(restaurant_id));
    }
  }, [dispatch, restaurant_id]);

  const restaurantZones = useSelector((state) => state.restaurantZones);
  const { zones = [] } = restaurantZones;

  const closedExceptions = useSelector((state) => state.closedExceptions);
  const { exceptions } = closedExceptions;

  const [showModal, setShowModal] = useState(false);
  const [zone_list, setZone_list] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [numberOfAcceptance, setNumberOfAcceptance] = useState(0);
  const [showExceptionDaysDeleteModal, setShowExceptionDaysDeleteModal] = useState(false);
  const [exception_id, setException_id] = useState(false);
  const [numbersList, setNumbersList] = useState(() => {
    const list = [];
    for (let i = 0; i <= 100; i++) {
      list.push(i);
    }
    return list;
  });

  useEffect(() => {
    const placeOfTables = zones.map((zone) => zone.place_of_table);
    setZone_list(placeOfTables);
    setSelectedZones(placeOfTables);
    const joinedString = placeOfTables.join(',');
    setFormData({ ...formData, ['list_of_zones']: String(joinedString) });
  }, [zones]);

  const [formData, setFormData] = useState({
    restaurant: parseInt(restaurant_id),
    closed_days_start: formatDate(new Date()),
    closed_days_end: formatDate(new Date()),
    start_time: '',
    end_time: '',
    list_of_zones: '',
    number_of_acceptance: '0',
  });

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNumberOfAcceptance = (value) => {
    setFormData({ ...formData, ['number_of_acceptance']: String(value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createClosedExceptions(restaurant_id, formData));
    setShowModal(false);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateRangeChange = (ranges) => {
    setDateRange([
      {
        ...ranges.selection,
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
    ]);

    setFormData({
      ...formData,
      closed_days_start: ranges.selection.startDate
        ? formatDate(ranges.selection.startDate)
        : '',
      closed_days_end: ranges.selection.endDate
        ? formatDate(ranges.selection.endDate)
        : '',
    });
  };

  const handleSelectZones = (newSelectedItems) => {
    const joinedString = newSelectedItems.join(',');
    setFormData({ ...formData, ['list_of_zones']: joinedString });
    setSelectedZones(newSelectedItems);
  };

  const handleExceptionElimination = async (value) => {
    try {
      const response = await api.delete(
        `/restaurants/${restaurant_id}/closedexceptions/${value}`
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setShowExceptionDaysDeleteModal(false);
    dispatch(fetchClosedExceptions(restaurant_id));
  };

  const handleModalClose = () => {
    setShowExceptionDaysDeleteModal(false);
  };
  const handleModalOpen = (value) => {
    setShowExceptionDaysDeleteModal(true);
    setException_id(value);
  };

  return (
    <Container>
      <Header>
        <Title>Exceções ao Horário de Funcionamento</Title>
        <StyledButton onClick={handleShow}>
          Adicionar <FontAwesomeIcon icon={faPlus} />
        </StyledButton>
      </Header>
      {Array.isArray(exceptions) ? (
        <ExceptionsList>
          {exceptions.map((exception) => (
            <HorizontalItemBox key={exception.id}>
              <Row>
                <Col xs={6} md={3}>{exception.closed_days_start} {exception.closed_days_start !== exception.closed_days_end && <> / {exception.closed_days_end}</>}</Col>
                <Col sm={0} md={4}>{exception.list_of_zones.replace(/,/g, ', ')}</Col>
                <Col sm={0} md={2}>{exception.number_of_acceptance}</Col>
                <Col sm={0} md={2}>{exception.start_time} - {exception.end_time}</Col>
              </Row>
              <ActionButton onClick={() => handleModalOpen(exception.id)}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </ActionButton>
            </HorizontalItemBox>
          ))}
        </ExceptionsList>
      ) : (
        <Loader />
      )}

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Nova Exceção ao Horário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <FormGroup controlId="dateRange">
              <Form.Label>Data de Início e Fim</Form.Label>
              <StyledDateRangePicker
                ranges={dateRange}
                onChange={handleDateRangeChange}
                minDate={new Date()}
                showDateDisplay={false}
                months={1}
                direction="horizontal"
              />
            </FormGroup>

            <FormGroup controlId="startTime">
              <Form.Label>Hora de Início</Form.Label>
              <Form.Control
                type="time"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup controlId="endTime">
              <Form.Label>Hora de Fim</Form.Label>
              <Form.Control
                type="time"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
                required
              />
            </FormGroup>

            <FormGroup controlId="zones">
              <Form.Label>Salas</Form.Label>
              <CustomSelectArray
                items={zone_list}
                onSelectChange={handleSelectZones}
                selectedItems={selectedZones}
              />
            </FormGroup>

            <FormGroup controlId="acceptanceLimit">
              <Form.Label>Limite de Clientes</Form.Label>
              <DropDownButton
                options={numbersList}
                handleOptionChange={handleNumberOfAcceptance}
                initialOption={numberOfAcceptance}
              />
            </FormGroup>

            <div className="text-right">
              <SubmitButton type="submit">
                Guardar
              </SubmitButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showExceptionDaysDeleteModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Apagar Exceção</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem a certeza de que deseja apagar esta exceção?</Modal.Body>
        <Modal.Footer>
          <StyledButton onClick={handleModalClose} variant="secondary">
            Fechar
          </StyledButton>
          <StyledButton onClick={() => handleExceptionElimination(exception_id)} variant="danger">
            Apagar
          </StyledButton>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

function formatDate(date) {
  const d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
}

export default ClosedExceptions;
