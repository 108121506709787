import React from 'react';

export const TableIconSvg = ({
  width = 19.09,
  height = 29.85,
  style = {},
  strokeColor = '#5ace8c', // Default stroke color set to #f5bf42, can be overridden via props
}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.09 29.85"
    width={width} // Default width from original SVG, can be overridden via props
    height={height} // Default height from original SVG, can be overridden via props
    style={style}
  >
    <defs>
      <style>
        {`.cls-1 {
          fill: none;
          stroke: ${strokeColor}; /* Use the strokeColor prop here */
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }`}
      </style>
    </defs>
    <rect className="cls-1" x=".75" y="6.78" width="17.59" height="16.28" rx="1.3" ry="1.3" />
    <path className="cls-1" d="M5.02,5.41C5.02,2.84,7.11.75,9.68.75s4.66,2.09,4.66,4.66" />
    <path className="cls-1" d="M14.07,24.43c0,2.57-2.09,4.66-4.66,4.66s-4.66-2.09-4.66-4.66" />
  </svg>
);
