import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import 'moment-timezone';

const AutoFillAddress = ({ inputRef, onPlaceSelected, onAddressChange, onCountryChange, onPostalCodeChange, onNumberFloorChange, onTimeZoneSelected }) => {
  let autocomplete;

  const loadGoogleMapsScript = () => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA7wQgzKIx0-y6j3pfy341jX6LmYq5_Sc4&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      initAutocomplete();
    };
  };

  const initAutocomplete = () => {
    autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
      fields: ["address_components", "geometry", "formatted_address"],
      types: ["address"],
    });

    autocomplete.addListener("place_changed", fillInAddress);
  }

  const fillInAddress = () => {
    const place = autocomplete.getPlace();
  
    if (!place.geometry || !place.formatted_address) {
      console.error('Place not valid');
      return;
    }
  
    let postalCode = '';
  
    // Iterate through address components to find the postal_code type
    for (const component of place.address_components) {
      const componentType = component.types[0];
      if (componentType === 'postal_code') {
        postalCode = component.long_name;
        break;
      }
    }
    console.log('place: ', place);

    // Retrieve timezone based on the latitude and longitude of the place
    const apiKey = 'AIzaSyA7wQgzKIx0-y6j3pfy341jX6LmYq5_Sc4';
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    const timestamp = Math.floor(Date.now() / 1000);  // Current timestamp in seconds
    const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${apiKey}`;
    let timezone;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        console.log('data :  ', data)
        const timezoneId = data.timeZoneId;
        timezone = timezoneId;
        console.log('Timezone ID: ', timezoneId);
      })
      .catch(error => {
        console.error('Error retrieving timezone:', error);
      });
    // Call the provided callback functions to update the parent component's state
    onPlaceSelected(place);
    onAddressChange(place.formatted_address);
    onCountryChange(place.address_components.find(component => component.types.includes('country'))?.long_name || '');
    onPostalCodeChange(place.address_components.find(component => component.types.includes('postal_code'))?.long_name || '');
    onNumberFloorChange(place.address_components.find(component => component.types.includes('street_number'))?.long_name || '');
    onTimeZoneSelected(timezone);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      initAutocomplete();
    } else {
      loadGoogleMapsScript();
    }
  }, [inputRef, onPlaceSelected, onAddressChange, onCountryChange, onPostalCodeChange, onNumberFloorChange]);

  return null;
};

export default AutoFillAddress;
