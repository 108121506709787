import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

// Styled components
const DropdownButton = styled.button`
  background-color: #9FBBC1;
  color: white;
  border: none;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom:5px;

  &:hover {
    background-color: #bcdee6;
  }
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: ${(props) => (props.open ? "block" : "none")};
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.2s ease-in;

  &:hover {
    background-color: #9FBBC1;
  }
`;

const FilterButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

// FilterButton Component
const FilterButton = ({ selectedOption, setSelectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <FilterButtonWrapper>
      <DropdownButton onClick={toggleDropdown}>
        <FontAwesomeIcon icon={faFilter} />
        {selectedOption || "Filter"}
      </DropdownButton>
      <DropdownMenu open={isOpen}>
        <DropdownItem onClick={() => handleOptionClick("Horário")}>
          Horário
        </DropdownItem>
        <DropdownItem onClick={() => handleOptionClick("Pessoas <")}>
            Pessoas {"<"}
        </DropdownItem>
        <DropdownItem onClick={() => handleOptionClick("Pessoas >")}>
            Pessoas {">"}
        </DropdownItem>
      </DropdownMenu>
    </FilterButtonWrapper>
  );
};

export default FilterButton;
