import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { logout } from '../actions/userActions'; // Adjust path based on your project structure

const SuspensionAccountWarning = () => {
  const dispatch = useDispatch();

  // Get managersInfo from state
  const manager_info = useSelector(state => state.crudManager)
  const { managerInfo } = manager_info

  // Check if the account is suspended
  const isSuspended = managerInfo && new Date(managerInfo.suspend_account_date) > new Date();
  const suspensionDate = isSuspended ? new Date(managerInfo.suspend_account_date).toLocaleDateString('pt-PT') : null;
  // Logout handler
  const handleLogout = () => {
    dispatch(logout());
  };

  // Only render the modal if the account is suspended
  if (!isSuspended) return null;

  return (
    <Modal show={isSuspended} centered>
      <Modal.Header>
        <Modal.Title>Aviso de Suspensão</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>A sua conta está suspensa até {suspensionDate}</p>
        <Button variant="danger" onClick={handleLogout} className="mt-3">
          Sair da conta
        </Button>
        <p className="mt-3">Qualquer dúvida, contacte com o seu gerente.</p>
      </Modal.Body>
    </Modal>
  );
};

export default SuspensionAccountWarning;
