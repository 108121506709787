import { createSlice } from '@reduxjs/toolkit'

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : 0;
const pushSubscriptionFromStorage = localStorage.getItem('userSubscription') ?
    JSON.parse(localStorage.getItem('userSubscription')) : 0;
const user_login_info = localStorage.getItem('login_info') ?
    JSON.parse(localStorage.getItem('login_info')) : 0;

const initialStates = userInfoFromStorage === 0 ? 
        {
            value:0,
            login_info: user_login_info,
        }  
    : userInfoFromStorage && pushSubscriptionFromStorage ?
        {
            userInfo: userInfoFromStorage,
            pushSubscription: pushSubscriptionFromStorage,
            login_info: user_login_info,
        }
    :   
        {
            userInfo: userInfoFromStorage,
            login_info: user_login_info,
        };

const initialState = 
        {
            value:0 ,
        }  

const initialUserLoginInfoState = user_login_info != 0 ?
        {
            login_info: user_login_info,
        }
        :
        {
            value:0,
        }
const initialUserInfoFromStorageState = userInfoFromStorage != 0 && user_login_info != 0?
        {
            userInfo: userInfoFromStorage,
            login_info: user_login_info,
        }
        : userInfoFromStorage != 0 ?
        {
            userInfo: userInfoFromStorage,
        }
        : user_login_info != 0 ?
        {
            login_info: user_login_info,
        }
        :
        {
            value:0,
        }
const initialPushSubscriptionFromStorageState = pushSubscriptionFromStorage != 0 ?
        {
            pushSubscription: pushSubscriptionFromStorage,
        }
        :
        {
            value:0,
        }
  
export const userLoginReducerSlice = createSlice({
    name: 'userLoginReducer',
    initialState:initialUserInfoFromStorageState,
    reducers: {
    userLoginRequest: (state) => {
        state.isLoading = true;
        state.userInfo = false;
    },
    userLoginSuccess: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.userInfo = action.payload;
        state.isLoading = false;
    },
    userLoginFail: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.userInfo = false;
        },
    userLogout: () => {
        return {}
    },
    },
  })

  const initialStateRegister = {
    username_exists:false
  }

  export const userRegisterReducerSlice = createSlice({
    name: 'userRegisterReducer',
    initialState:initialStateRegister,
    reducers: {
    userRegisterRequest: (state) => {
        state.isLoading = true;
    },
    userRegisterSuccess: (state) => {
        state.isLoading = false;
    },
    username_exists: (state) => {
        state.username_exists = true;
        },
    username_not_exists: (state) => {
        state.username_exists = false;
        },
    userRegisterFail: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        },
    userLogout: (state, action) => {
        return {}
        },
    },
  })

  export const userUpdateReducerSlice = createSlice({
    name: 'userDetailsReducer',
    initialState,
    reducers: {
    userUpdateRequest: (state) => {
        state.isLoading = true;
    },
    userUpdateSuccess: (state) => {
        state.isLoading = false;
        state.success = true
    },
    userUpdateFail: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        },
    // Password recovery
    passwordRecoveryRequest: (state) => {
        state.loadingPasswordRecovery = true;
    },
    passwordRecoverySuccess: (state, action) => {
        state.loadingPasswordRecovery = false;
        state.passwordRecovery = action.payload
    },
    passwordRecoveryFail: (state, action) => {
        state.passwordRecoveryError = action.payload;
        state.loadingPasswordRecovery = false;
        },
    // Verify password recovery code
    verifyPasswordRecoveryRequest: (state) => {
        state.loadingVerifyPasswordRecovery = true;
    },
    verifyPasswordRecoverySuccess: (state, action) => {
        state.loadingVerifyPasswordRecovery = false;
        state.verifyPasswordRecovery = action.payload
    },
    verifyPasswordRecoveryFail: (state, action) => {
        state.passwordVerifyRecoveryError = action.payload;
        state.loadingVerifyPasswordRecovery = false;
        },
    // Change password recovery code
    changePasswordRecoveryRequest: (state) => {
        state.loadingChangePasswordRecovery = true;
    },
    changePasswordRecoverySuccess: (state, action) => {
        state.loadingChangePasswordRecovery = false;
        state.changePasswordRecovery = action.payload
    },
    changePasswordRecoveryFail: (state, action) => {
        state.passwordChangeRecoveryError = action.payload;
        state.loadingChangePasswordRecovery = false;
        },
    userUpdateReset: (state) => {
        return {}
        },
    },
  })

  export const fetchUserSubscriptionSlice = createSlice({
    name: 'fetchUserSubscription',
    initialState:initialPushSubscriptionFromStorageState,
    reducers: {
    fetchUserSubscriptionRequest: (state) => {
        state.isLoadingSubscription = true;
    },
    fetchUserSubscriptionSuccess: (state, action) => {
        state.isLoadingSubscription = false;
        state.pushSubscription = action.payload
    },
    fetchUserSubscriptionFail: (state, action) => {
        state.error = action.payload;
        state.isLoadingSubscription = false;
        },
    userSubscriptionReset: (state) => {
        return {}
        },
    },
  })

  export const storeUserSubscriptionSlice = createSlice({
    name: 'fetchUserSubscription',
    initialState,
    reducers: {
    storeUserSubscriptionRequest: (state) => {
        state.isLoadingSubscription = true;
    },
    storeUserSubscriptionSuccess: (state) => {
        state.isLoadingSubscription = false;
        state.subscriptionStored = true
    },
    storeUserSubscriptionFail: (state, action) => {
        state.error = action.payload;
        state.isLoadingSubscription = false;
        },
    },
  })

  export const getRestaurantSubscriptionSlice = createSlice({
    name: 'getRestaurantSubscription',
    initialState,
    reducers: {
    GetRestaurantSubscriptionRequest: (state) => {
        state.isLoadingGetRestaurantSubscription = true;
    },
    GetRestaurantSubscriptionSuccess: (state, action) => {
        state.isLoadingGetRestaurantSubscription = false;
        state.restaurantSubscription = action.payload;
    },
    getRestaurantSubscriptionError: (state, action) => {
        state.errorGetRestaurantSubscription = action.payload;
        state.isLoadingGetRestaurantSubscription = false;
        },
    },
  })

  export const getRestaurantClientsSlice = createSlice({
    name: 'getRestaurantClients',
    initialState,
    reducers: {
    GetRestaurantClientsRequest: (state) => {
        state.isLoadingGetRestaurantClients = true;
    },
    GetRestaurantClientsSuccess: (state, action) => {
        state.isLoadingGetRestaurantClients = false;
        state.restaurantClients = action.payload;
    },
    getRestaurantClientsError: (state, action) => {
        state.errorGetRestaurantClients = action.payload;
        state.isLoadingGetRestaurantClients = false;
        },
    GetRestaurantInfoClientRequest: (state) => {
        state.isLoadingGetRestaurantInfoClient = true;
    },
    GetRestaurantInfoClientSuccess: (state, action) => {
        state.isLoadingGetRestaurantInfoClient = false;
        state.restaurantInfoClients = action.payload;
    },
    GetRestaurantInfoClientError: (state, action) => {
        state.errorGetRestaurantInfoClient = action.payload;
        state.isLoadingGetRestaurantInfoClients = false;
        },


    DeleteClientInfoRequest: (state) => {
      state.isLoadingDeleteClientInfo = true;
    },
    DeleteClientInfoSuccess: (state, action) => {
        state.isLoadingDeleteClientInfo = false;
        state.deleteClientInfo = action.payload;
    },
    DeleteClientInfoError: (state, action) => {
        state.errorDeleteClientInfo = action.payload;
        state.isLoadingDeleteClientInfo = false;
        },
    
    },
    

  })

  export const fiscalAdressCrudSlice = createSlice({
    name: 'fiscalAdressCrud',
    initialState,
    reducers: {
      createFiscalAdressStart: (state) => {
        state.isLoadingCreateFiscalAdress= true;
      },
      createFiscalAdressSuccess: (state, action) => {
        state.createFiscalAdress = action.payload;
        state.isLoadingCreateFiscalAdress = false;
      },
      createFiscalAdressError: (state, action) => {
        state.createFiscalAdressError = action.payload;
        state.isLoadingCreateFiscalAdress = false;
      },
      // Get Fiscal address
      getFiscalAdressStart: (state) => {
        state.isLoadingGetFiscalAdress= true;
      },
      getFiscalAdressSuccess: (state, action) => {
        state.getFiscalAdress = action.payload;
        state.isLoadingGetFiscalAdress = false;
      },
      getFiscalAdressError: (state, action) => {
        state.getFiscalAdressError = action.payload;
        state.isLoadingGetFiscalAdress = false;
      },
      // Update Fiscal address
      updateFiscalAdressStart: (state) => {
        state.isLoadingUpdateFiscalAdress = true;
      },
      updateFiscalAdressSuccess: (state, action) => {
        state.updateFiscalAdress = action.payload;
        state.isLoadingUpdateFiscalAdress = false;
      },
      updateFiscalAdressError: (state, action) => {
        state.updateFiscalAdressError = action.payload;
        state.isLoadingUpdateFiscalAdress = false;
      },
      // Delete Fiscal address
      deleteFiscalAdressStart: (state) => {
        state.isLoadingDeleteFiscalAdress= true;
      },
      deleteFiscalAdressSuccess: (state, action) => {
        state.deleteFiscalAdress = action.payload;
        state.isLoadingDeleteFiscalAdress = false;
      },
      deleteFiscalAdressError: (state, action) => {
        state.deleteFiscalAdressError = action.payload;
        state.isLoadingDeleteFiscalAdress = false;
      },
    },
  });
  
  export const getSubscriptionPackagesSlice = createSlice({
    name: 'subscriptionPackages',
    initialState,
    reducers: {
      getSubscriptionPackagesStart: (state) => {
        state.isLoadingGetSubscriptionPackages= true;
      },
      getSubscriptionPackagesSuccess: (state, action) => {
        state.getSubscriptionPackages = action.payload;
        state.isLoadingGetSubscriptionPackages = false;
      },
      getSubscriptionPackagesError: (state, action) => {
        state.getSubscriptionPackagesError = action.payload;
        state.isLoadingGetSubscriptionPackages = false;
      },
    },
  });

  export const whatsappSubscriptionsSlice = createSlice({
    name: 'whatsappSubscriptions',
    initialState,
    reducers: {
      getWhatsappSubscriptionsRequest: (state) => {
        state.isLoadingWhatsappSubscriptions= true;
      },
      getWhatsappSubscriptionsSuccess: (state, action) => {
        state.getWhatsappSubscriptions = action.payload;
        state.isLoadingWhatsappSubscriptions = false;
        state.getWhatsappSubscriptionsError = false;
      },
      getWhatsappSubscriptionsError: (state, action) => {
        state.getWhatsappSubscriptionsError = action.payload;
        state.isLoadingWhatsappSubscriptions = false;
        state.getWhatsappSubscriptions = false;
      },
      // create whatsapp subscription
      createWhatsappSubscriptionRequest: (state) => {
        state.isLoadingCreateWhatsappSubscription = true;
      },
      createWhatsappSubscriptionSuccess: (state, action) => {
        state.createWhatsappSubscription = action.payload;
        state.isLoadingCreateWhatsappSubscription = false;
        state.createWhatsappSubscriptionError = false;
      },
      createWhatsappSubscriptionError: (state, action) => {
        state.createWhatsappSubscriptionError = action.payload;
        state.isLoadingCreateWhatsappSubscription = false;
        state.createWhatsappSubscription = false;
      },
    },
  });