import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Card, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUtensils, faEnvelope, faCheckCircle, faSearch, faPhone, faCopy, faSquarePhoneFlip } from '@fortawesome/free-solid-svg-icons';
import { getManagers, registerManager, deleteManager, updateManager } from '../actions/managersActions';
import { fetchRestaurantList } from '../actions/restaurantActions';
import { verify_username } from '../actions/userActions'
import Messages from '../components/Messages'
import WaitCheckMark  from '../components/WaitCheckMark';
import {getDialCode, getCountryCode} from '../components/CountryCode';
import  SwitchOnAndOff  from '../components/SwitchOnAndOff'
import DatePicker from 'react-datepicker'; // Make sure to install react-datepicker
import 'react-datepicker/dist/react-datepicker.css';


import 'react-phone-input-2/lib/style.css'; // Import the default CSS for react-phone-input-2
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'; // Import the Bootstrap CSS for additional styling

const InputOverlay = styled.input`
  position: absolute;
  top: 33px;
  font-size:16px;
  left: 105px; 
  height: 36px;
  border: none;
  border-radius: 0 3px 0 0;
  background: none;
  outline: none;
  background-color:none;
  padding-bottom:4px;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    max-width: 100%;
    box-sizing: border-box;
    overflow:hidden;
  }
`;

const SearchBar = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid grey;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  top: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 10px;
  background-color: white;
  justify-content:center;
`;

const CardBottom = styled.div`
  padding: 10px;
  background-color: #8FEBC5;
`;

const StyledRow = styled(Row)`
  height: 100%;
  align-items: center;
  width: 100%;
  position:relative;
  margin-left:0;
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : "start"};
`;

const StyledColForIcons = styled(Col)`
  align-items: center;
  position:relative;
  justify-content:start;
`;

const Styledh6 = styled.h6`
  font-size: 1rem;
  margin-left:3px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin:2px;
`;

const Smallh6 = styled.h6`
  font-size: 0.8rem;
  margin: 0;
`;

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px; 
  position:absolute;
  top:3px;
  left:2rem;
`;
const ModalCard = styled(Card)`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
  margin-bottom: 10px;
`;

const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
    width: 100%;
  }
  .modal-content {
    background-color: #F5F5F5;
  }
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid black;
`;
const ModalTitle = styled(Modal.Title)`
  margin-left: 10px;
  color: ${props => props.selected ? "white" : "black"};
`;
const ModalTitleText = styled.span`
  color: ${props => props.selected ? "white" : "black"};
`;


const ModalCardText = styled(Card.Text)`
  color: ${props => props.selected ? "white" : "black"};
`;

const CardBottomClients = styled.div`
  padding: 3px;
  background-color: #8FEBC5;
`;
const ShowInfo = styled.div`
  padding: 4px;
  margin:2px;
  font-size: 15px;
  background-color: #4ec7a5;
  color:white;
  border-radius:3px;
`;

const StyledColEnd = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const ScrollableDiv = styled.div`
    overflow-x: auto;
    white-space: nowrap;
    max-height: 50px;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const Restaurant = styled.div`
    background-color: #44bd6a;
    color: white;
    border-radius: 7%;
    display: inline-block;
    padding: 2px;
    margin: 5px;
`;



const ITEMS_PER_PAGE = 25;

function ManagersCreationScreen() {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [managerToDeleteID, setManagerToDeleteID] = useState('');

    const [getEmail, setGetEmail] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [permission, setPermission] = useState('');
    const [restaurant_choices, setRestaurant_choices] = useState([]);
    const [manager_id, setManager_id] = useState('');
    
    const [whatsapp_notification_token, setWhatsapp_notification_token] = useState('');
    const [getWhatsappNotifications, setGetWhatsappNotifications] = useState('');

    const [readReservations, setReadReservations] = useState(false);
    const [editReservations, setEditReservations] = useState(false);
    const [changeStatus, setChangeStatus] = useState(false);
    const [editRestaurantSettings, setEditRestaurantSettings] = useState(false);
    const [editClosedDays, setEditClosedDays] = useState(false);

    const [countryCode, setCountryCode] = useState("us");
    const [countryCodeNumber, setCountryCodeNumber] = useState('351');
    const [phone, setPhone] = useState('');
    const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const [isWaiting, setIsWaiting] = useState(false);
    const [update_or_create, setUpdate_or_create] = useState('');

    const [isSuspending, setIsSuspending] = useState(false);
    const [dateToSuspend, setDateToSuspend] = useState('');

    useEffect(() => {
      if (countryCodeNumber === '') {
        const detectedCountryCode = detectCountryCode();
        setCountryCode(detectedCountryCode);
        setCountryCodeNumber(getDialCode(detectedCountryCode));
      } else {
        setCountryCode(getCountryCode(countryCodeNumber));
      }
      
    }, [countryCodeNumber]);

    const detectCountryCode = () => {
      const userCountryCode = navigator.language.split("-")[1];
      const formattedCountryCode = userCountryCode ? userCountryCode.toLowerCase() : "us";
      // Add more specific checks if needed based on the origin or location detection mechanism
      return formattedCountryCode; // Default to 'PT' if the country code is not detected
    };

    useEffect(() => {
      // Check if countryCode and phone are not null or undefined before concatenating
      if (countryCodeNumber && phoneWithoutCountryCode) {
        setPhone(String(countryCodeNumber) + String(phoneWithoutCountryCode));
      }
    }, [phoneWithoutCountryCode, countryCodeNumber]);

    const MAX_CHARACTER_LIMIT = 10; 

    const handleInputPhoneChange = (e) => {
      const inputValue = e.target.value;
  
      if (inputValue.length <= MAX_CHARACTER_LIMIT) {
        setPhoneWithoutCountryCode(inputValue);
      }
    };



    useEffect(() => {
      console.log('emaill: ', email)
      if (email &&  email.length > 0) {
        dispatch(verify_username(email));
      }
    },[email]);

    useEffect(() => {
        console.log('restaurant_choices: ', restaurant_choices)
    },[restaurant_choices]);
    useEffect(() => {
      dispatch(getManagers());
      dispatch(fetchRestaurantList());
    }, [dispatch])

    const manager_info = useSelector(state => state.crudManager)
    const { managersInfo } = manager_info

    const restaurantList = useSelector(state => state.restaurantList)
    const {  restaurants ,isLoading, error } = restaurantList

    const verify_username_existance = useSelector(state => state.userRegister)
    const {  username_exists  } = verify_username_existance

    useEffect(() => {
        if (managersInfo) {
            const results = managersInfo.filter(manager =>
                manager.user.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                manager.user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        }
        
    }, [searchTerm, managersInfo]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const handlePrevPage = () => {
        setCurrentPage(prev => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(prev => prev + 1);
    };
  
    const handleClose = () => setShowModal(false);

    const handleShow = (param, permissions, 
                        allowed_restaurants, name, 
                        email, id, getEmails, 
                        the_country_code, the_phone_number, read_reservations, 
                        edit_reservations, change_status, 
                        edit_restaurant_settings, edit_closed_days, suspensionDate) => {
      setShowModal(true);
      setUpdate_or_create(param);
      if (param === 'update') {
        setCountryCodeNumber(the_country_code)
        setPhone(the_phone_number);
        setPhoneWithoutCountryCode(the_phone_number);
        setEmail(email);
        setName(name);
        setRestaurant_choices(getRestaurantsByIds(allowed_restaurants));
        setPermission(permissions);
        setManager_id(id);
        setGetEmail(getEmails)
            // Set new permission fields for 'update'
        setReadReservations(read_reservations);
        setEditReservations(edit_reservations);
        setChangeStatus(change_status);
        setEditRestaurantSettings(edit_restaurant_settings);
        setEditClosedDays(edit_closed_days);
        setDateToSuspend(suspensionDate)
        // Parse suspension date and compare it with today's date
        const suspension = new Date(suspensionDate);
        const today = new Date();
        if (suspension < today) {
          setIsSuspending(false);
        } else {
          setIsSuspending(true);
        }
      } else {
        setEmail('');
        setName('');
        setRestaurant_choices([]);
        setPermission('');
        setPhone('')
        setCountryCodeNumber('')
        setWhatsapp_notification_token('')
        setDateToSuspend('')
        setIsSuspending(false)
        setGetWhatsappNotifications(false)
        setGetEmail(false)
        // Reset new permission fields for 'create'
        setReadReservations(false);
        setEditReservations(false);
        setChangeStatus(false);
        setEditRestaurantSettings(false);
        setEditClosedDays(false);
      }
    };

  const handleUpdateManager = () => {
    let ids = restaurant_choices.map(restaurant => restaurant.id);
    if (restaurant_choices.length > 0){
      let phoneWithoutCountryCoda;
        if (phone.length > 5) {
          const countryCodeLength = countryCodeNumber.length;
          let phoneNumber = phone;
      
          // Remove leading plus if it exists
          if (phoneNumber[0] === '+') {
            phoneNumber = phoneNumber.slice(1);
          }
      
          // Remove leading country code number
          phoneWithoutCountryCoda = phoneNumber.slice(countryCodeLength);
        }
      setIsWaiting(true);
      setShowModal(false);
      console.log('dateToSuspend: ', dateToSuspend)
      dispatch(updateManager(
        permission, 
        ids, 
        false, 
        manager_id, 
        getEmail, 
        countryCodeNumber, 
        phoneWithoutCountryCoda,
        readReservations,
        editReservations,
        changeStatus,
        editRestaurantSettings,
        editClosedDays,
        dateToSuspend,
      ));
      setTimeout(() => {
        setIsWaiting(false);
      }, 1500);
    }
};
  
const handleCreateManager = () => {
  let ids = restaurant_choices.map(restaurant => restaurant.id);

  // Ensure required fields are valid before dispatching the action
  if (!username_exists && restaurant_choices.length > 0) {
      setIsWaiting(true);
      setShowModal(false);

      // Dispatch the action with the new permission fields included
      dispatch(registerManager(
          name, 
          email, 
          permission, 
          ids, 
          getEmail, 
          countryCodeNumber, 
          phone, 
          whatsapp_notification_token,            // Ensure this is handled
          getWhatsappNotifications,              // Ensure this is handled
          readReservations,                      // New permission field
          editReservations,                      // New permission field
          changeStatus,                          // New permission field
          editRestaurantSettings,                // New permission field
          editClosedDays                         // New permission field
      ));

      // Simulate waiting for the operation to complete
      setTimeout(() => {
          setIsWaiting(false);
      }, 1500);
  }
  };

    const paginatedResults = searchResults.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
    const hasPrevPage = currentPage > 1;
    const hasNextPage = currentPage < Math.ceil(searchResults.length / ITEMS_PER_PAGE);

    useEffect(()=> {
        if (paginatedResults) {
            setCurrentPage(1);
        }
    },[paginatedResults]);

    const handleRestaurantSelection = (e, restaurant) => {
        if (e.target.checked) {
            setRestaurant_choices(prevChoices => [...prevChoices, restaurant]);
        } else {
            setRestaurant_choices(prevChoices => prevChoices.filter(choice => choice !== restaurant));
        }
    };

    const getRestaurantsByIds = (allowed_restaurants) => {
      console.log('ids: ', allowed_restaurants)
      if (Array.isArray(allowed_restaurants) && Array.isArray(restaurants)) {
          return restaurants.filter(restaurant => allowed_restaurants.includes(restaurant.id));
      }
      // Return an empty array if the conditions are not met
      return [];
  };

    const handleDelete = () => {
        dispatch(deleteManager(managerToDeleteID));
        setShowRemoveModal(false);
    };

    const handleShowDeleteModal = (id) => {
      setShowRemoveModal(true);
      setManagerToDeleteID(id);
  };

    useEffect(()=> {
      if (isSuspending === false) {
        setDateToSuspend(null)
      }
    },[isSuspending])

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  

  return (
    <Container>
        <StyledCard className="p-0">
            <CardTop>
            <StyledRow>
                <StyledCol sm={4} md={4}>
                <h6> Contas Gerentes </h6>
                </StyledCol>
                <StyledColEnd sm={6} md={8}>
                    <ActionButtons>
                        <Button variant="info" size="sm" className="mr-2" onClick={() => handleShow('create')}> 
                        <Smallh6>Criar Gerente <FontAwesomeIcon icon={faPlus} className="ml-2"/> </Smallh6>
                        </Button>
                    </ActionButtons>
                </StyledColEnd>
            </StyledRow>
            </CardTop>
            <CardBottom/> 
        </StyledCard>
        <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Apagar conta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    De certeza que pretende apagar a conta?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleDelete}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

        <StyledModal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title style={{marginLeft: '10px'}}>Criar conta </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                <Form>
                <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Nome</Form.Label>
                    <Form.Control required type="text" value={name} onChange={(e) => setName(e.target.value)}  disabled={update_or_create === 'update'} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control required type="email" value={email} onChange={(e) => setEmail(e.target.value)}  disabled={update_or_create === 'update'} />
                    {username_exists && email.length > 0 && update_or_create === 'create' && <Messages variant='danger'> Este email já se encontra em uso.</Messages>}
                </Form.Group>
                <Form.Group>
                  <div style={{ position: 'relative' }}>
                      <Form.Label>Telemóvel</Form.Label>
                      <StyledPhoneInput
                      country={countryCode}
                      onChange={setCountryCodeNumber}
                      inputClass="form-control"
                      placeholder="Número de telemóvel"
                      inputProps={{
                      name: 'phone',
                      required: true,
                      readOnly: true, // make the input field read-only
                      position:'relative',
                      }}
                      > 
                      
                      </StyledPhoneInput>
                      
                      
                      <InputOverlay maxlength={10} value={phoneWithoutCountryCode} onChange={handleInputPhoneChange}/>
                      
                      <FontAwesomeIcon size='xl' icon={faSquarePhoneFlip} style={{color: "#48c778", position: 'absolute', top: '39px', left: '270px'}} />
                      
                  </div>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formPermission">
                    <Form.Label>Permissões</Form.Label>
                    <Form.Select
                        aria-label="Default select example"
                        value={permission}
                        onChange={(e) => setPermission(e.target.value)}
                    >
                        <option value="">Selecionar permissão</option>
                        <option value="full_access">Acesso completo</option>
                        <option value="accept_cancel_reservations">Aceitar/Cancelar reservas</option>
                        <option value="attended_or_not_attended">Confirmar chegadas</option>
                    </Form.Select>
                </Form.Group>   */}
                <div>
                  <h3>Permissões</h3>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', padding: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <input
                              type="checkbox"
                              checked={readReservations}
                              onChange={(e) => setReadReservations(e.target.checked)}
                          />
                          <label>Ler Reservas</label>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <input
                              type="checkbox"
                              checked={editReservations}
                              onChange={(e) => setEditReservations(e.target.checked)}
                          />
                          <label>Editar Reservas</label>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <input
                              type="checkbox"
                              checked={changeStatus}
                              onChange={(e) => setChangeStatus(e.target.checked)}
                          />
                          <label>Alterar Estado</label>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <input
                              type="checkbox"
                              checked={editRestaurantSettings}
                              onChange={(e) => setEditRestaurantSettings(e.target.checked)}
                          />
                          <label>Editar Configurações do Restaurante</label>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <input
                              type="checkbox"
                              checked={editClosedDays}
                              onChange={(e) => setEditClosedDays(e.target.checked)}
                          />
                          <label>Editar Dias Fechados</label>
                      </div>
                  </div>
              </div>

                
                <Form.Group controlId="restaurants">
                    <Form.Label>Selecionar Restaurantes</Form.Label>
                    <div className="restaurant-list">
                        {restaurants && 
                        <>
                        {restaurants.map((restaurant, index) => (
                                <label key={index} className={`restaurant-item ${restaurant_choices.includes(restaurant) ? "selected-restaurant" : ""}`} htmlFor={`restaurant-${index}`}>
                                    <Form.Check 
                                        
                                        id={`restaurant-${index}`}
                                        hidden
                                        checked={restaurant_choices.includes(restaurant)}
                                        onChange={(e) => handleRestaurantSelection(e, restaurant)}
                                    />
                                    <div>{restaurant.restaurant_name} | {restaurant.adress}</div>
                                </label>
                            ))}
                        </>
                             
                        }
                       
                    </div>
                </Form.Group>
                <Form.Group>
                <div className="container mt-5">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="getEmailCheck">
                      Receber e-mails
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={getEmail}
                      id="getEmailCheck"
                      onChange={() => setGetEmail(!getEmail)}
                      checked={getEmail}
                    />
                  </div>
                </div>
                </Form.Group>
                  {update_or_create === 'update' &&
                  <Form.Group>
                  <div className="container mt-5 d-flex align-items-center">
                    <div style={{ marginRight: '10px' }}>Suspender conta?</div>
                    <SwitchOnAndOff isOn={isSuspending} handleToggle={() => setIsSuspending(!isSuspending)} />
                  </div>

                  {isSuspending && (
                     <div className="mt-3">
                     <Form.Label>Selecione a data de suspensão</Form.Label>
                     <DatePicker
                       selected={dateToSuspend ? new Date(dateToSuspend) : null}
                       onChange={(date) => setDateToSuspend(formatDate(date))}
                       dateFormat="yyyy-MM-dd"
                       placeholderText="Escolha uma data"
                       className="form-control"
                     />
                   </div>
                  )}
                </Form.Group>
                }
                
                </Form>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Fechar
                </Button>
                {update_or_create === 'create' ? 
                <Button variant="primary" onClick={handleCreateManager}>
                    Criar conta
                </Button>
                :
                <Button variant="primary" onClick={handleUpdateManager}>
                    Guardar
                </Button>
              }
                
            </Modal.Footer>
            </StyledModal>
            {isWaiting && <WaitCheckMark/>}
            {searchResults ?
            paginatedResults.map((manager) => (
            <StyledCard key={manager.id}>
                <StyledRow>
                    <StyledCol xs={12} sm={12} md={4}>
                        <Styledh6>
                        {manager.user.name}
                        </Styledh6>
                    </StyledCol>
                    <StyledCol xs={3} sm={3} md={4}>
                      {/* <ShowInfo>
                        {manager.permissions === 'full_access' ? 'Acesso completo' 
                        : manager.permissions === 'accept_cancel_reservations' ? 'Aceitar/Cancelar reservas' 
                        : 'Confirmar chegadas' }
                      </ShowInfo> */}
                      
                    </StyledCol>
                      {/*<StyledCol sm={4} md={4}>
                      <ScrollableDiv>
                        {(Array.isArray(manager.allowed_restaurants)) && 
                        <>
                            {getRestaurantsByIds(manager.allowed_restaurants).map((restaurant, index) => (
                                <Restaurant key={index}>
                                    {restaurant.restaurant_name}
                                </Restaurant>
                            ))}
                        </>
                        }
                    </ScrollableDiv>
                      </StyledCol> */}
                    <StyledColEnd xs={12} sm={9} md={4}>
                    <StyledButton
                      variant="info"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleShow(
                        'update', 
                        manager.permissions, 
                        manager.allowed_restaurants, 
                        manager.user.name, 
                        manager.user.email, 
                        manager.user.id, 
                        manager.getEmails, 
                        manager.country_code, 
                        manager.phone_number,
                        manager.read_reservations,
                        manager.edit_reservations,
                        manager.change_status,
                        manager.edit_restaurant_settings,
                        manager.edit_closed_days,
                        manager.suspend_account_date
                      )}
                    >
                      Editar
                    </StyledButton>
                    <StyledButton
                      variant="danger"
                      size="sm"
                      className="mr-2"
                      onClick={() => handleShowDeleteModal(manager.user.id)}
                    >
                      remover x
                    </StyledButton>
                    </StyledColEnd>
                </StyledRow>
              <CardBottomClients></CardBottomClients>
            </StyledCard>
            ))
        :
        <>
            Nothing to be found.
        </>
        }
        <div>
            <button className='btn' onClick={handlePrevPage} disabled={!hasPrevPage}>Página anterior</button>
            <button className='btn' onClick={handleNextPage} disabled={!hasNextPage}>Próxima página</button>
        </div>
    </Container>
  )
}

export default ManagersCreationScreen