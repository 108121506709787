import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { fetchReservations, postReservationAccept,
        tablesToChange as fetchTablesToChange,
        changeTables, getReservationSums, updateCustomerAttendance,
        fetchRestaurantDetails, fetchTables, fetchConnectTablesConfig,
        fetchAllReservations, updateArrangedTable, resetAttendance  } from '../actions/restaurantActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { fetchCancelReservation,
         fetchZones,
         fetchReservationBlockedWeekdays,
         checkCancelAction,
         reservationUpdate,
         updateReservation,
         postPhoneOrEmailConfirmation} from '../actions/reservationsActions';
import ReservationHistory  from '../components/ReservationHistory';
import Loader from '../components/Loader';
import Messages from '../components/Messages';
import UsePrevious from '../components/UsePrevious';
import TablesModal  from '../components/ChangeTables';
import WaitCheckMark  from '../components/WaitCheckMark';
import AttendanceButtons  from '../components/AttendanceButtons';
import NotificationBell  from '../components/NotificationBell';
import CustomDatePickerReservationSums from '../components/CustomDatePickerReservationSums';
import ReservationScreen from './ReservationScreen';
import CustomSelectAttendedOrNot from '../components/CustomSelectAttendedOrNot'
import TableInPlace from '../components/TableInPlace'
import axios from 'axios';
import SubMenu from '../components/SubMenu';
import FiltersComponent from '../components/FiltersComponent';
import ReservationChat from '../components/ReservationChat';
import styled from 'styled-components';
import CustomSelect from '../components/SelectableCustom';
import CustomSelectArray from '../components/CustomSelectArray'
import CustomSelectArrayOfObjects from '../components/CustomSelectArrayOfObjects'
import { Row, Col, Card } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlus, faCalendar, 
        faFilter, faSun, faMap, faCircleDot, 
        faStopwatch, faChair, faUsers, faCircleXmark,
        faFilePen, faRectangleXmark, faEnvelope, 
        faWheelchairMove, faBaby, faBell, faReply,
        faSearch, faSquareCheck, faEye, faPhone, 
        faCircleCheck, faGear, faXmark, faCheck, faPhoneSlash,
        faLock } from '@fortawesome/free-solid-svg-icons';
import Separator from '../components/Separator';
import LoaderComponent from '../components/LoaderComponent'
import ReservationStatusBox from '../components/ReservationStatusBox'
import CompareChanges from '../components/CompareChanges';
import ShowCustomerInfo from '../components/ShowCustomerInfo';
import FilterButtonDropDown from '../components/FilterButton';
import {TableIconSvg} from '../icons/SvgIcons';

const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap:20px;
  margin-bottom: 1rem;
`;

const FilterItem = styled.div`
  margin: 0px;
`;

const FilterLabel = styled.label`
  display: block;
  margin:0;
  font-size:1.1rem;
`;

const FilterInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ReservationGrid = styled.div`
  display: grid;
`;
 /* 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
 */


  const ReservationCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border: 1px solid #cbd1cf;
  border-radius:4px;
  padding: 0;
  position: relative;
  overflow:hidden;
  width:100%;
  max-height:400px;
  margin-bottom:4px;
`;

const ReservationTop = styled.div`
  display: flex;
  align-items:center;
  width: 100%;
  height: 28px;
  background-color: ${props => (props.status === 'Cancelled' || 
                      props.status === 'Cancelled_By_Customer' ||
                      props.status === 'Cancelled_By_Restaurant' ||
                      props.status === 'Did_Not_Attend' ? '#e8733c'
                      : props.status === 'Cancelled_By_Expired_Code' ? '#dcb1e0'
                      : props.status === 'Pending_from_customer_Warning' ? '#d6a7c1'
                      : props.status === 'Edited' ? '#7e538a'
                      : props.status === 'Pending_from_restaurant' ? '#cacf44'
                      : props.status === 'Pending_from_customer' ? '#959657'
                      : props.status === 'Late' ? '#ebb052'
                      : '#58c49f')};
  border: 1px solid gray;
`;

const ReservationBot = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  width:100%;
  height:auto;
`;

const ReservationBotItems = styled.div`
  display: flex;
  margin:5px;
`;
const ReservationBotItemss = styled.div`
  display: flex;
  margin:4px;
`;
const ReservationBoxWrap = styled.div `
  display: flex;
  flex-wrap:wrap;
  padding:5px;
  margin:0;
  width:100%;
`;
const ReservationBotItemsContainer = styled.div`
  display: flex;
  width:100%;
`;

const ReservationFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right:5px;
`;

const ReservationTitle = styled.h2`
  font-size: 1rem;
  color: ${props => (props.status === 'Cancelled' ? 'white' 
  : props.status === 'Pending_from_restaurant' ? 'black' : props.status === 'Edited'? 'white' : 'white')};
  margin-top:4px;
  margin-left:5px;
`;
const ReservationDate = styled.h2`
  font-size: 1rem;
  color: ${props => (props.status === 'Cancelled' ? 'white' 
  : props.status === 'Pending_from_restaurant' ? 'black' : props.status === 'Edited'? 'white' : 'white')};
  margin-top:0px;
  margin-left:5px;
`;


const AttendanceButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width:auto;
  height:auto;
`;

const FirstWarningMessage = styled.div`
  display:flex;
  gap:10px;
  position:absolute;
  top: 0.2rem;
  right: 4rem; 
  color: #d15823;
`;


const ReservationInfo = styled.p`
  margin-bottom: 1rem;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
`;

const SuccessButton = styled.div`
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items: center;
  background-color: #a5d9d7;
  border: 1px solid #e8e6e6;
  width:50%;
  height: 100%;
  padding:0;
  margin:0;
  border-radius:0;
`;

const DangerButton = styled.div`
  cursor:pointer;
  display:flex;
  justify-content:center;
  align-items: center;
  background-color:#a5d9d7;
  border-radius:0;
  border: 1px solid #e8e6e6;
  padding:0;
  width:50%;
  height:100%;
  margin:0;
`;

const EditButton = styled(Button)`
  display:flex;
  justify-content:center;
  align-items: center;
  background-color:#59d9d4;
  border:0;
  top:0;
  left:0;
  height:28px;
  width:100%;
  padding:0;
  margin:0;
  border-radius:0;
  &:hover {
    background-color: #228a86;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  margin: 0; 
  padding: 0;
  font-size:28px;
`;


const SuccessButtonFilter = styled(Button)`
  width:100px;
  background-color:  #00b33c;
  border-color:  #00b33c;

  &:hover {
    background-color: #80ffaa;
    border-color: #80ffaa;
  }
`;

const DateFilterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  margin-bottom: 1rem;
`;

const OtherFiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;


const DateButton = styled.div`
  cursor:pointer;
  display:flex;
  padding: 0;
  height:32px;
  border-radius: 5px;
  border: 1px solid gray;
  width:145px;
  overflow:hidden;
  margin-bottom:5px;
`;
const DateButtonLeft = styled.div`
  padding: 0;
  background-color: #8FEBC5;
  height:100%;
  width:30px;
  text-align:center;
  border-right: 1px solid gray; 
  cursor:pointer;
`;
const DateButtonRight = styled.div`
  display: flex;  
  align-items: center;  
  font-size: 12px;
  color: gray;
  text-align:center;
  width:80px;
  cursor:pointer;
  padding-left:5px;
  border-right: 1px solid gray; 
`;
const WeekDayRight = styled.div`
  display: flex;  
  align-items: center;  
  font-size: 12px;
  color: gray;
  text-align:center;
  width:35px;
  cursor:pointer;
  padding-left:5px;
`;

const FilterButton = styled.div`
  cursor:pointer;
  display:flex;
  padding: 0;
  margin:0;
  height:auto;
  border-radius: 5px;
  border: 1px solid gray;
  width:100%;
  overflow:hidden;
`;
const FilterButtonLeft = styled.div`
  display:flex;
  justify-content:center;
  padding:0;
  margin:0;
  background-color: #8FEBC5;
  height:auto;
  width:30px;
  border-right: 1px solid gray; 
  cursor:pointer;
  align-items:center;
`;
const FilterButtonRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: gray;
  text-align: start;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  overflow-x: auto;

  /* Modern Scrollbar Style */
  &::-webkit-scrollbar {
    width: 10px; /* Adjust width for vertical scrollbar */
    height: 10px; /* Adjust height for horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background-color: #f4f4f4; /* Color of the track of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the track */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* Color of the thumb of scrollbar */
    border-radius: 10px; /* Adjust border-radius for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8a8a8; /* Color of the thumb of scrollbar when hovered */
  }

  /* For Internet Explorer */
  -ms-overflow-style: auto;
`;
const StyledCard = styled(Card)`
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const CardTop = styled.div`
  padding: 10px;
  min-height:50px;
  background-color: white;
  justify-content:center;
`;

const CardBottom = styled.div`
  padding: 5px;
  background-color: #8FEBC5;
`;

const CreateZoneButton = styled(ModernButton)`
  position: absolute;
  display:flex;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const CreateZoneButtonWaitingList = styled(ModernButton)`
  position: absolute;
  display:flex;
  top: 45px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const LargeIconPlus = styled(FontAwesomeIcon)`
  margin-left: 7px; 
`;

const CancelIcon = styled(FontAwesomeIcon)`
  top: 3px; 
  right: 3px;
  position:absolute;
  --fa-primary-color: #d35612;
  --fa-secondary-color: #ffffff;
`;

const TableBox = styled.div`
  display: inline-block;
  margin: 2px;
  width: auto;
  height: 25px;
  padding-left: 3px;
  padding-right:3px;
  line-height: 25px;
  text-align: center;
  background-color: #3ECF95;
  color: white;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box; 
`;

const ModalBackgroundToCustomDate = styled.div`
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const CustomDateContainer = styled.div`
  position: fixed;  
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);  // centers the div
  z-index: 120;
`;



const StyledH7 = styled.div`
  font-size:12px;
  color: white;
`;

const SearchInput = styled.input`
  padding: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid gray;
  height:23px;
  width: 150px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: -40px;
  top: 4px;
`;

const SearchBar = styled.div`
  position: relative;
  margin:5px;
  width: 100px;
`;

const FormCheck = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 3px;
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  &.makeItGreen:checked {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2300A400' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 75%;
  }
`;

const FilterBoxes = styled.div`
  display: flex;
  white-space:nowrap;
  align-items:center;
  font-size:10px;
  justify-content:center;
  min-width: 50px;
  height: 18px;
  border-radius:4px;
  border: 1px solid black;
  padding:3px;
  margin:2px 1px 2px 1px; 
  color: #ffffff;
  overflow:hidden;
  background-color: ${props => (props.backgroundColor !== null ? props.backgroundColor : '#ffffff')};
`;

const TablesUsedSeatSums = styled.div`
  color: ${props => (props.reservation_number > props.total_number_of_seats ? 'red' : '#5ace8c')};
`;





function getTableInfo(selectedTablesList, allTablesList) {
  let tableInfo = {};
  
  Object.keys(selectedTablesList).forEach(zoneName => {
    const tableNumbers = selectedTablesList[zoneName].map(table => table.number);
    
    allTablesList.forEach(tablesInZone => {
      if (tablesInZone[0] === zoneName) {
        tableNumbers.forEach(tableNumber => {
          const tableDetails = tablesInZone.find(table => table.split(';')[0] === tableNumber);
          if (tableDetails) {
            if (tableInfo[zoneName]) {
              tableInfo[zoneName].push(tableDetails);
            } else {
              tableInfo[zoneName] = [tableDetails];
            }
          }
        });
      }
    });
  });


  // Convert the object into a list of lists
  let tableInfoList = [];
  Object.keys(tableInfo).forEach(zone => {
    tableInfoList.push([zone, ...tableInfo[zone]]);
  });

  return tableInfoList;
}

function listToSelectedTablesFormat(zoneName, tableNumbers) {
  if (!Array.isArray(tableNumbers)) {
    throw new Error("Expected parameter to be an array");
  }

  const tables = tableNumbers.map(tableNumber => ({
    number: tableNumber,
    status: "0",
  }));

  return {
    [zoneName]: tables,
  };
}


function ReservationFromRestaurantScreen() {
  const reservationsFromRestaurant = useSelector((state) => state.reservationsFromRestaurant);
  const { reservations, isLoading=false, error, allReservations } = reservationsFromRestaurant;
  const tablesChangeds= useSelector((state) => state.changedTables);
  const { tablesChanged = [], isLoadingChangeTables } = tablesChangeds;

  const dispatch = useDispatch();
  const id = useParams();

  const today = new Date();
  const lastManualUpdate = useRef(null);
  const formattedToday = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  const [dateFilter, setDateFilter] = useState(formattedToday);
  const [placeFilter, setPlaceFilter] = useState('');
  const [attendedOrNot, setAttendedOrNot] = useState(['Compareceu','Não compareceu']);
  const [data, setData] = useState(null);
  const prevData = UsePrevious(data);
  const [statusFilter, setStatusFilter] = useState(id.status === 'all_pending_reservations' ? id.status : 'Todos');
  const [showModal, setShowModal] = useState(false);
  const [tokens, setTokens] = useState('');
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [isCreatingReservation, setIsCreatingReservation] = useState(false);
  const [totalPeople, setTotalPeople] = useState(0);
  const [totalReservations, setTotalReservations] = useState(0);
  const [totalPeopleLeft, setTotalPeopleLeft] = useState(0);
  const [totalReservationsLeft, setTotalReservationsLeft] = useState(0);
  const [reset, setReset] = useState(false);
  const [isAllReservations, setIsAllReservations] = useState(false);
  const [theReservations, setTheReservations] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState('');

  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [updatedZones, setZonesWithAll] = useState('');
  const [tokena, setTokena] = useState('');
  const [restaurant_id, setRestaurant_id] = useState('');
  const [ tablesList, setTablesList] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  const [acceptMultipleReservations, setAcceptMultipleReservations] = useState(false);
  const [canceltMultipleReservations, setCanceltMultipleReservations] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMultipleReservations, setSelectedMultipleReservations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activateLoader, setActivateLoader] = useState(false);
  const [sortedResults, setSortedResults] = useState([]);
  const [all_reservations, setAll_reservations] = useState([]);
  const [showNoTablesMessage, setShowNoTablesMessage] = useState(false);
  const [dropDownFilterButton, setDropDownFilterButton] = useState('Horário');

  const [isShowCustomerInfoModalActive, setIsShowCustomerInfoModalActive] = useState(false);

  const [reservation_or_waiting_list, setReservation_or_waiting_list] = useState('reservation');
  const reservation_or_waiting_list_options = ['reservation', 'waiting_list']

  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [phoneIsConfirmed, setPhoneIsConfirmed] = useState(false);
  const [completePhone, setCompletePhone] = useState('');


  const [current_reservation_id, setCurrent_reservation_id] = useState('');
  
  const reservationAccept = useSelector(state => state.reservationAccept)
  const { messageAccept, isLoadingAccept } = reservationAccept

  const reservationCancel = useSelector(state => state.cancelReservation)
  const { cancelMessage, isLoadingCancel } = reservationCancel
 
  const reservationZone = useSelector((state) => state.reservationZone);
  const { zones, isZonesLoading, zonesError } = reservationZone;

  const customerAttendUpdates = useSelector((state) => state.customerAttendUpdate);
  const { customerAttend, isLoadingCustomerAttend, errorCustomerAttend,
          isLoadingChangeTableNumber, changeTableNumber, errorChangeTableNumber } = customerAttendUpdates;

  const restaurant_info = useSelector(state => state.restaurantDetails)
  const {  restaurants } = restaurant_info

  const reservation_info = useSelector(state => state.reservationCustomersMenu)
  const {  reservation } = reservation_info

  const restaurantTimeZone = useSelector((state) => state.reservationBlockedWeekDays.restaurantTimeZone);

  const reservation_created_selector = useSelector((state) => state.createReservation);
  const {  reservation_created, checkCancel, isCheckCancelLoading } = reservation_created_selector

  const all_tables = useSelector(state => state.restaurantTables.tables);

  const fetchedFilters = useSelector((state) => state.filtersModels.fetchedFilters);

  const connectTables = useSelector(state => state.connectTablesConfig);
  const { connectTablesConfig = [] } = connectTables;

  const manager_info = useSelector(state => state.crudManager)
  const { managerInfo } = manager_info

  const [showTablesModal, setShowTablesModal] = useState(false);

  const [usedTables, setUsedTables] = useState({});

  const socket = useRef(null);

  function dispatch_reservation_wait()  {
    setIsWaiting(true);
    setTimeout(() => {
      dispatch(getReservationSums(id.restaurant_id, dateFilter));
      dispatch(fetchReservations(id.restaurant_id, dateFilter, statusFilter));
      dispatch(fetchAllReservations(id.restaurant_id, null, null, null, 'all_pending_reservations_from_all_time'));
    }, 700); // Adjust the delay as needed
    setTimeout(() => {
      setIsWaiting(false);
    }, 1300); // Adjust the delay as needed
  }

  useEffect(() => {
    dispatch(getReservationSums(id.restaurant_id, dateFilter));
  },[dateFilter])

  useEffect(() => {
    setAll_reservations(allReservations)
  },[allReservations])


  useEffect(()=> {
    if (restaurantTimeZone) {
      setCurrentDateTime(moment.tz(restaurantTimeZone.timezone))
    }
  },[restaurantTimeZone])

  useEffect(() => {
    if (isLoading === false || isLoadingChangeTableNumber === false) {
      setActivateLoader(false);
    } else {
      setActivateLoader(true);
    }
  }, [isLoading, isLoadingChangeTableNumber])


  useEffect(() => {
    if (restaurantTimeZone) {
      const timer = setInterval(() => {
        setCurrentDateTime(moment.tz(restaurantTimeZone.timezone));
      }, 60000); // 60,000 milliseconds = 1 minute
  
      // Cleanup: clear the interval when the component is destroyed
      return () => clearInterval(timer);
    }
    
  }, [restaurantTimeZone]);



  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
  
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) return false;
  
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  }
  useEffect(() => {
    if (reservations && allReservations) {
        let isReservationsAll = false;
        let shouldUpdate = false;
        let allTokensPresent = true;

      if (isAllReservations && allReservations.length > 0) {
        isReservationsAll = true;
        // Check if every token in allReservations is present in theReservations
        let allTokensPresent = true;
        if (Array.isArray(theReservations)) {
          allTokensPresent = deepEqual(allReservations, theReservations) 
        } 
        // Update only if not all tokens are present
        if (!allTokensPresent) {
          shouldUpdate = true;
        }
      } else {
        isReservationsAll = false;
        setIsAllReservations(false);
        // Similar logic could be applied for `reservations` and `theReservations`
        console.log('reservations: ', reservations)
        console.log('theReservations 111: ', theReservations)
        if (Array.isArray(theReservations)) {
          allTokensPresent = deepEqual(reservations, theReservations) 
        }
        // Update only if not all tokens are present
        if (!allTokensPresent || reservations.length === 0) {
          shouldUpdate = true;
        }
      }
      console.log('isReservationsAll: ', isReservationsAll)
      if (shouldUpdate) {
        setTheReservations(isReservationsAll ? allReservations : reservations);
        setSelectedMultipleReservations([]);
        console.log('reservations were updated')
      }
      console.log('allReservations after: ', allReservations)
      console.log('reservations after: ', reservations)
      console.log('theReservations 222:: ', theReservations)
    }
  }, [reservations, allReservations, isAllReservations]);


  useEffect(() => {
    if (theReservations && theReservations.length > 0) {
      console.log('theReservations: ', theReservations);
      console.log('selectedFilters inside: ', selectedFilters);
  
      // Normalize text function to handle spaces, diacritics, and special characters
      const normalizeText = (text) => {
        return text
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
          .replace(/\W+/g, ' ') // Replace non-word characters (symbols, punctuation) with spaces
          .trim();
      };
  
      const normalizedSearchTerm = normalizeText(searchTerm);
      const searchTokens = normalizedSearchTerm.split(/\s+/); // Split search term by any whitespace (spaces, tabs, etc.)
  
      // Filter reservations by search term
      const filteredBySearchTerm = theReservations.filter((reservation) => {
        // Normalize the fields to compare
        const normalizedFullName = normalizeText(reservation.full_name);
        const normalizedPhoneNumber = normalizeText(reservation.phone_number);
        const normalizedTableNumber = reservation.table_number
          ? normalizeText(reservation.table_number)
          : '';
  
        // Check if all tokens are present across any of the fields
        return searchTokens.every((token) => {
          return (
            normalizedFullName.includes(token) ||
            normalizedPhoneNumber.includes(token) ||
            normalizedTableNumber.includes(token)
          );
        });
      });
  
      // Filter reservations by time range
      const filteredByTimeRange = filteredBySearchTerm.filter((reservation) => {
        // Directly compare the time strings
        return selectedFilters.some((filter) => {
          const reservationTime = reservation.time;
          const startTime = filter.start_time;
          const endTime = filter.end_time;
  
          // Ensure times are in comparable format, e.g., "HH:MM"
          const formatTime = (time) => (time.length === 5 ? time : `0${time}`);
          console.log('formatTime(reservationTime): ', formatTime(reservationTime));
          console.log('formatTime(startTime): ', formatTime(startTime));
          console.log('formatTime(endTime): ', formatTime(endTime));
          return (
            formatTime(reservationTime) >= formatTime(startTime) &&
            formatTime(reservationTime) < formatTime(endTime)
          );
        });
      });
      console.log('filteredByTimeRange: ', filteredByTimeRange);
  
      // Filter reservations by table place preference
      const filtersWithPlaceOfTable = filteredByTimeRange.filter((filter) => {
        // First, check if table_place_preference exists and then check if place_of_table is in placeFilter
        return filter.table_place_preference ? placeFilter.includes(filter.table_place_preference.place_of_table) :true ;
      });
  
      // Set the final search results
      setSearchResults(filtersWithPlaceOfTable);
    } else {
      setSearchResults(theReservations);
    }
  }, [searchTerm, theReservations, selectedFilters, placeFilter]);
  
    
  

  useEffect(() => {
    if (searchResults) {
      const sortOrder = {
        'Cancelled_By_Customer_Warning': 0,
        'Late': 1,
        'Pending_from_customer': 2,
        'Pending_from_restaurant': 3,
        'Edited': 4,
        'Awaiting_customer_reconfirmation': 4,
        'waiting_list': 5,
        'Accepted_edit': 6,
        'Rejected_edition': 6,
        'Accepted_Without_Table': 6,
        'Accepted': 6,
        'Accepted_With_Table': 6,
        'Attended': 8,
        'attended': 8,
        'Did_Not_Attend': 9,
        'Cancelled_By_Customer': 10,
        'Cancelled_By_Restaurant': 11,
        'Cancelled_By_Expired_Code': 12,
        'Cancelled': 13
      };
  
      const orderedResults = [...searchResults].sort((a, b) => {
        const statusOrder = sortOrder[a.status] - sortOrder[b.status];
        if (statusOrder !== 0) return statusOrder;
  
        // Function to check table_number validity
        const isValidTableNumber = (tableNumber) => {
          if (tableNumber === null || tableNumber === undefined) return 0;
          const tableNumberStr = typeof tableNumber === 'string' ? tableNumber : String(tableNumber);
          return tableNumberStr.trim() !== '' && tableNumberStr.trim() !== 'null' ? 1 : 0;
        };
  
        const aHasTableNumber = isValidTableNumber(a.table_number);
        const bHasTableNumber = isValidTableNumber(b.table_number);
  
        if (!aHasTableNumber && bHasTableNumber) return -1;
        if (aHasTableNumber && !bHasTableNumber) return 1;
  
        // Sorting by the selected filter from the dropdown
        if (dropDownFilterButton === 'Horário') {
          // Sort by time
          const convertTimeToComparableNumber = (timeString) => {
            if (!timeString) return 0;
            const [hours, minutes] = timeString.split(':');
            return parseInt(hours, 10) * 100 + parseInt(minutes, 10);
          };
          const timeA = convertTimeToComparableNumber(a.time);
          const timeB = convertTimeToComparableNumber(b.time);
          return timeA - timeB;
        } else if (dropDownFilterButton === 'Pessoas >') {
          // Sort by number_of_people (highest to lowest)
          return b.number_of_people - a.number_of_people;
        } else if (dropDownFilterButton === 'Pessoas <') {
          // Sort by number_of_people (lowest to highest)
          return a.number_of_people - b.number_of_people;
        }
  
        return 0; // Default case if no match
      });
  
      setSortedResults(orderedResults);
    }
  }, [searchResults, dropDownFilterButton]);
  
  
  
  

  useEffect(() => {
    if (id.date != 'null') {
      setDateFilter(id.date);
    }
  }, [id.date])

  useEffect(() => {
    
    dispatch(getReservationSums(id.restaurant_id, dateFilter));
    dispatch(fetchRestaurantDetails(id.restaurant_id));
    dispatch(fetchTables(id.restaurant_id));
    dispatch(fetchConnectTablesConfig(id.restaurant_id));
    dispatch(fetchReservationBlockedWeekdays(id.restaurant_id));
  }, [dispatch, id.restaurant_id]);
  
  useEffect(() => {
      dispatch(fetchReservations(id.restaurant_id, dateFilter));
  },[dateFilter, statusFilter, id.restaurant_id])

  useEffect(() => {
    dispatch(fetchZones(id.restaurant_id));
  },[dateFilter])

  useEffect(() => {
    setTablesList(reservations)
  },[reservations])

  useEffect(() => {
    if (sortedResults) {
      const total = sortedResults.reduce((acc, curr) => {
        if (curr.status === 'Accepted' ||
            curr.status === 'Accepted_edit' ||
            curr.status === 'Awaiting_customer_reconfirmation' ||
            curr.status === 'waiting_list' ||
            curr.status === 'Rejected_edition' ||
            curr.status === 'Accepted_With_Table' ||
            curr.status === 'Accepted_Without_Table' ||
            curr.status === 'Attended' ||
            curr.status === 'Late' ||
            curr.status === 'Did_Not_Attend') {
          return acc + curr.number_of_people;
        }
        return acc;
      }, 0);
      
      const reservationsTotal = sortedResults.reduce((acc, curr) => {
        if (curr.status === 'Accepted' ||
            curr.status === 'Accepted_edit' ||
            curr.status === 'Awaiting_customer_reconfirmation' ||
            curr.status === 'waiting_list' ||
            curr.status === 'Rejected_edition' ||
            curr.status === 'Accepted_With_Table' ||
            curr.status === 'Accepted_Without_Table' ||
            curr.status === 'Attended' ||
            curr.status === 'Late' ||
            curr.status === 'Did_Not_Attend') {
          return acc + 1;
        }
        return acc;
      }, 0);

      const totalLeft = sortedResults.reduce((acc, curr) => {
        if (curr.status === 'Accepted' ||
            curr.status === 'Accepted_edit' ||
            curr.status === 'Awaiting_customer_reconfirmation' ||
            curr.status === 'waiting_list' ||
            curr.status === 'Rejected_edition' ||
            curr.status === 'Accepted_With_Table' ||
            curr.status === 'Late' ||
            curr.status === 'Accepted_Without_Table') {
          return acc + curr.number_of_people;
        }
        return acc;
      }, 0);

      const reservationsTotalLeft = sortedResults.reduce((acc, curr) => {
        if (curr.status === 'Accepted' ||
            curr.status === 'Accepted_With_Table' ||
            curr.status === 'Accepted_edit' ||
            curr.status === 'Awaiting_customer_reconfirmation' ||
            curr.status === 'waiting_list' ||
            curr.status === 'Rejected_edition' ||
            curr.status === 'Late' ||
            curr.status === 'Accepted_Without_Table') {
          return acc + 1;
        }
        return acc;
      }, 0);

      setTotalPeople(total);
      setTotalReservations(reservationsTotal);
      setTotalPeopleLeft(totalLeft);
      setTotalReservationsLeft(reservationsTotalLeft);
    } else {
      if (sortedResults) {
        setSortedResults(false);
      }
    }
  }, [sortedResults]);



  useEffect(() => {
    if (zones) {
      console.log('zones: ', zones.zones.slice())
      setZonesWithAll((prevState) => {
        const updatedZones = zones.zones.slice();
        return updatedZones;
      });
    }
  }, [dispatch, zones]);


  // choose the right filter according to the time
  useEffect(() => {
    if (fetchedFilters) {
      const checkAndSetFilters = () => {
        const currentDate = new Date(); // Get today's date
  
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();
  
        const firstMatchingFilter = fetchedFilters.find((filter) => {
          // Construct full date-time strings by combining the current date with the time
          const startTime = new Date(`${currentDate.toDateString()} ${filter.start_time}`);
          const endTime = new Date(`${currentDate.toDateString()} ${filter.end_time}`);
  
          console.log('startTime:', startTime);
          console.log('endTime:', endTime);
  
          // Extract hours and minutes from start and end times
          const startHours = startTime.getHours();
          const startMinutes = startTime.getMinutes();
          const endHours = endTime.getHours();
          const endMinutes = endTime.getMinutes();
  
          // Check if the current time is within the start and end time bounds
          const isAfterStartTime = currentHours > startHours || (currentHours === startHours && currentMinutes >= startMinutes);
          const isBeforeEndTime = currentHours < endHours || (currentHours === endHours && currentMinutes <= endMinutes);
  
          return isAfterStartTime && isBeforeEndTime;
        });
  
        // Only set the filter if it exists
        if (firstMatchingFilter) {
          setSelectedFilters([firstMatchingFilter]); // Set only the first matching filter
        }
      };
  
      // Execute immediately to set initial state
      checkAndSetFilters();
  
      // Set the interval to update every 30 minutes
      const intervalId = setInterval(checkAndSetFilters, 30 * 60 * 1000); // 30 minutes in milliseconds
  
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [fetchedFilters, setSelectedFilters]);

  useEffect(() => {
    setPlaceFilter(updatedZones);
  },[updatedZones, restaurant_id]);

const dateFilterRef = useRef(dateFilter);
const placeFilterRef = useRef(placeFilter);
const statusFilterRef = useRef(statusFilter);

useEffect(() => {
  dateFilterRef.current = dateFilter;
  placeFilterRef.current = placeFilter;
  statusFilterRef.current = statusFilter;
}, [dateFilter, statusFilter]);


  
  const handleCustomFilterChange = (value, filterName) => {
    if (filterName === 'status') setStatusFilter(value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Accepted':
        return 'green';
      case 'Cancelled':
        return 'red';
      case 'Pending_from_restaurant':
        return 'yellow';
      case 'Edited':
        return 'purple';
      default:
        return 'black';
    }
  };

  const handleResetAttendance = () => {
    dispatch(resetAttendance());
    setShowNoTablesMessage(!showNoTablesMessage);
  }
  
  const handleShowModal = (value) => {
    if (value) {
      setSelectedReservation(value);
      setTokens(value);
      setCanceltMultipleReservations(false);
    } else {
      setCanceltMultipleReservations(true);
    }
    setShowModal(true);
  };

 

  const handleAccept = () => {
    if (acceptMultipleReservations) {
      dispatch(postReservationAccept(selectedMultipleReservations, true));
      setSelectedMultipleReservations([]);
    } else {
      dispatch(postReservationAccept(selectedReservation, false));
    }
    setShowAcceptModal(false);
  };
  
  const handleShowAcceptModal = (value) => {
    if (value) {
      setSelectedReservation(value);
      setAcceptMultipleReservations(false);
    } else {
      const tokensToExclude = new Set(
        theReservations
          .filter(rt => rt.status === 'Accepted' || rt.status === 'Cancelled')
          .map(rt => rt.token)
      );
      const newSelectedMultipleReservations = selectedMultipleReservations.filter(
        token => !tokensToExclude.has(token)
      );
      setSelectedMultipleReservations(newSelectedMultipleReservations);
      setAcceptMultipleReservations(true);
    }
    setShowAcceptModal(true);
  };

  const handleCloseAcceptModal = () => {
    setShowAcceptModal(false);
  };

  const tablesToChange= useSelector((state) => state.availableTablesToChange);
  const { tables = [], isLoadingTables, errorTables } = tablesToChange;

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleCloseDateModal = () => {
    setShowDateModal(false);
  };

  const handleOpenDateModal = () => {
    setShowDateModal(true);
  };

  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleOpenFilterModal = () => {
    setShowFilterModal(true);
  };

  const handleModalSave = (selectedTables, token, ids) => {
    const fullTableInfo = getTableInfo(selectedTables, tables);
    dispatch(changeTables(token, fullTableInfo, ids));
    setShowTablesModal(false);
  };

  //() => handleShowTablesModal(reservation.restaurant, 
  //dateFilter, reservation.time, reservation.table_place_preference.id, 
  //reservation.tablesused, reservation.table_place_preference.place_of_table, 
  //reservation.token, reservation.estimatedtime)
  // Adjust the handleShowTablesModal function
  const handleShowTablesModal = (id, date, time, zone, usedTabless, zonas, tokens, estimatedtime) => {
    let usedTablesss = usedTabless.split(',');
    const myDictionary = {
      [zonas]: usedTablesss,
    };
    if (usedTabless === '') {
      usedTabless = 'no_tables'
    }
    const selectedTablesFormat = listToSelectedTablesFormat(zonas, usedTablesss);
    setUsedTables(selectedTablesFormat)
    dispatch(fetchTablesToChange(id, date, time, zone, usedTabless, estimatedtime));
    setShowTablesModal(true);
    setTokena(tokens);
    setRestaurant_id(id);
  };

  const handleOpenCreateReservationModal = () => {
    setIsReservationModalOpen(true);
    setReservation_or_waiting_list('reservation')
    setReset(!reset);
    setIsCreatingReservation(true);
    setTokens('');
  };
 
  const handleOpenEditReservationModal = (tokeni) => {
    setIsReservationModalOpen(true);
    setIsCreatingReservation(false);
    setReservation_or_waiting_list('reservation')
    setTokens(tokeni);
  };



  const handleCloseReservationModal = () => {
    setIsReservationModalOpen(false);
    setTokens();
  };

  const handleChange = event => {
    setSearchTerm(event.target.value);
};

const handleSelectMultipleReservations = (value) => {
  setSelectedMultipleReservations(prevSelected => {
    if (prevSelected.includes(value)) {
      return prevSelected.filter(token => token !== value);
    } else {
      return [...prevSelected, value];
    }
  });
}; 
const handleSelectMultipleReservationsAll = (reservationsList) => {
  // Filter out reservations with status of "Cancelled"
  const filteredReservationsList = reservationsList.filter(r => r.status !== "Cancelled");

  // Check if all tokens in filteredReservationsList are already in selectedMultipleReservations
  const allTokensPresent = filteredReservationsList.every(r => selectedMultipleReservations.includes(r.token));

  if (allTokensPresent) {
    // If all tokens are already present, remove them
    const newSelectedMultipleReservations = selectedMultipleReservations.filter(
      token => !filteredReservationsList.some(r => r.token === token)
    );
    setSelectedMultipleReservations(newSelectedMultipleReservations);
  } else {
    // Otherwise, add all tokens that are not already present
    const newTokens = filteredReservationsList
      .filter(r => !selectedMultipleReservations.includes(r.token))
      .map(r => r.token);
    setSelectedMultipleReservations([...selectedMultipleReservations, ...newTokens]);
  }
};


const handleSelectFilters = (newSelectedItems) => {
  setSelectedFilters(newSelectedItems);
  lastManualUpdate.current = new Date().getTime();
};

const handleCustomSelectPlaceOfTable = (newSelectedItems) => {
  setPlaceFilter(newSelectedItems);
};

const handleCheckCancel = (token) => {
  dispatch(checkCancelAction(token));
};

const updateSearchResultsTableNumber = (id, newTableNumber, status) => {
  setSearchResults(prevResults => {
    // Find the item with the matching ID
    const foundItem = prevResults.find(item => item.id === id);

    if (foundItem) {
      // Create a new array with the updated object
      return prevResults.map(item =>
        item.id === id ? { ...item, table_number: newTableNumber, status: status } : item
      );
      
    } else {
      // Handle the case where the item with the given ID is not found
      console.error("Invalid ID. Unable to update search results.");
      return prevResults;
    }
  });
};

const handleSetPhoneNumber = (country_code, phone_number, reservationID, isPhoneConfirmed) => {
    const countryCode = country_code;
    const phoneNumber = phone_number;
    const countryCodeLength = countryCode.length;
    const formattedPhoneNumber = phoneNumber.startsWith(countryCode) 
      ? countryCode + ' ' + phoneNumber.slice(countryCodeLength) 
      : phoneNumber;
      
    setCompletePhone(formattedPhoneNumber);
    setShowPhoneModal(!showPhoneModal);
    setCurrent_reservation_id(reservationID);
    setPhoneIsConfirmed(isPhoneConfirmed);
};

const showCustomerInfo = (country_code, phone_number) => {
  const countryCode = country_code;
    const phoneNumber = phone_number;
    const countryCodeLength = countryCode.length;
    const formattedPhoneNumber = phoneNumber.startsWith(countryCode) 
      ? countryCode + ' ' + phoneNumber.slice(countryCodeLength) 
      : phoneNumber;
    setCompletePhone(phone_number);
    setIsShowCustomerInfoModalActive(true);
};



  // This function is called when a new message is received from the WebSocket
  const handleReservationUpdate = (newReservation) => {
    setTheReservations((currentResults) => {
      const index = currentResults.findIndex(res => res.token === newReservation.token);
      let updatedResults = [...currentResults];

      // Convert both dates to Date objects
      const newReservationDate = new Date(newReservation.date);
      const filterDate = new Date(dateFilterRef.current);
      if (index !== -1) {
        // If reservation exists, update it
        // Compare the year, month, and day parts of the dates
        if (newReservationDate.getFullYear() === filterDate.getFullYear() &&
            newReservationDate.getMonth() === filterDate.getMonth() &&
            newReservationDate.getDate() === filterDate.getDate()) {
          // If reservation exist and dates match, update newReservation
          updatedResults[index] = newReservation;
        } else {
          // else remove it
          updatedResults = updatedResults.filter(reservation => {
            return reservation.token !== newReservation.token;
          });
        }
        
      } else {
        
        // Compare the year, month, and day parts of the dates
        if (newReservationDate.getFullYear() === filterDate.getFullYear() &&
            newReservationDate.getMonth() === filterDate.getMonth() &&
            newReservationDate.getDate() === filterDate.getDate()) {
          // If reservation doesn't exist and dates match, add newReservation
          updatedResults.push(newReservation);
        } 
        // If dates don't match, do not add newReservation
      }
      return updatedResults;
    });
    setAll_reservations((currentResults) => {
      const index = currentResults.findIndex(res => res.token === newReservation.token);
      let updatedResults = [...currentResults];

      if (index !== -1) {
        // If reservation exists, update it
        updatedResults[index] = newReservation;
      } else {
        // If reservation doesn't exist, add it
        updatedResults.push(newReservation);
      }

      // Sort the updated results based on your criteria (e.g., date)
      updatedResults.sort((a, b) => new Date(a.date) - new Date(b.date));

      return updatedResults;
    });
  };

  useEffect(() => {
    const websocketBaseURL = process.env.REACT_APP_WEBSOCKET_URL;
    socket.current = new WebSocket(`${websocketBaseURL}reser/${id.restaurant_id}/`);
    console.log('socket.current: ', socket.current)
    socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
    
        // Check for chat history type message
        if (data.type === 'reservation_update') {
          handleReservationUpdate(data.data);
        } else {
        }
    };
    
    socket.current.onopen = () => {
        console.log('WebSocket Connected');
    };

    socket.current.onclose = () => {
        console.log('WebSocket Disconnected');
    };

    return () => socket.current?.close();
  }, [restaurant_id]);

  useEffect(() => {
    console.log('restaurantTimeZone: ', restaurantTimeZone)
    console.log('sortedResults: ', sortedResults)
    console.log('searchResults: ', searchResults)
    console.log('reservations: ', reservations)
    console.log('theReservations: ', theReservations)
  },[restaurantTimeZone, sortedResults, searchResults, reservations])

  useEffect(() => {
    const checkReservations = () => {
      console.log('restaurantTimeZone.timezone: ', restaurantTimeZone.timezone)
      
      if (restaurantTimeZone) {
        // const currentTime = moment.tz(restaurantTimeZone.timezone);
        const currentTime = moment.tz('Europe/London');
        if (!currentTime.isValid()) {
          console.error('Failed to compute valid time for timezone: Europe/London');
          // Consider a fallback or notify users/administrators as appropriate
}
        console.log('currentTime: ', currentTime)
        const currentTimeToShowAttendance = currentTime.clone().add(24, 'hours');
        const currentTimeAfter = currentTime.clone().subtract(15, 'minutes');

        theReservations.forEach((reservation, index) => {
          const reservationDateTime = moment.tz(
            `${reservation.date} ${reservation.time}`,
            'YYYY-MM-DD HH:mm',
            restaurantTimeZone.timezone
          );
          // console.log('reservationDateTime: ', reservationDateTime)
          console.log('currentTimeAfter: ', currentTimeAfter)
          if (reservationDateTime.isBefore(currentTimeAfter) &&
              (reservation.status === 'Accepted' || 
                reservation.status === 'Accepted_edit' || 
                reservation.status === 'Awaiting_customer_reconfirmation' ||
                reservation.status === 'waiting_list' ||
                reservation.status === 'Rejected_edition' ||
               reservation.status === 'Accepted_With_Table' || 
               reservation.status === 'Accepted_Without_Table')) {
            dispatch(updateReservation(reservation.id, { status: 'Late' }, true));
          }
        });
      }
    };

    const intervalId = setInterval(checkReservations, 60000); // Sets the check to run every minute

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [restaurantTimeZone,  theReservations]);

  const calculateTotalSeats = (tablesUsed) => {
    let totalSeats = 0;
  
    console.log('tablesUsed: ', tablesUsed);
  
    // Convert tablesUsed (string of table numbers) into an array of integers
    const tableNumbers = tablesUsed
      .split(',') // Split the string by commas
      .map(tableNumber => parseInt(tableNumber.trim(), 10)) // Convert each to integer
      .filter(tableNumber => !Number.isNaN(tableNumber)); // Filter out invalid numbers
  
    // Retrieve the configurations for each item
    const configValues = Object.values(connectTablesConfig);
  
    // Define a counter to keep track of the table connections
    let tableConnectionIndex = 0;
  
    // Iterate through each table number and find the matching table object
    if (all_tables) {
      tableNumbers.forEach((tableNumber, index) => {
        // Find the corresponding table object in all_tables based on table ID
        const tableObj = all_tables.find(table => table.id === tableNumber);
        if (!tableObj) {
          console.warn(`Table with ID ${tableNumber} not found in all_tables`);
          return; // Skip if table object is not found
        }
    
        // Extract and convert the number of seats to an integer
        const numberOfSeats = parseInt(tableObj.number_of_seats, 10);
        if (Number.isNaN(numberOfSeats)) {
          console.warn(`Invalid number_of_seats for table ID ${tableNumber}`);
          return; // Skip if number_of_seats is not a valid number
        }
    
        // Determine the configuration index based on the connection logic:
        // 1st config for 2 tables, 2nd config for 3 tables, 3rd config for 4 tables,
        // and then cycle between 2nd and 3rd configs thereafter.
        let configIndex;
        if (tableConnectionIndex === 0) {
          configIndex = 0; // Use the first config for the initial connection
        } else {
          configIndex = (tableConnectionIndex % 2) + 1; // Alternate between 2nd and 3rd items
        }
    
        // Retrieve the configuration for the current table connection
        const config = configValues[configIndex];
    
        // Adjust the number of seats based on the configuration logic
        const adjustedSeats = numberOfSeats + (parseInt(config?.number_of_chairs, 10) || 0);
    
        // Add the adjusted number of seats to the total count
        totalSeats += adjustedSeats;
    
        // Increment the table connection index after processing the table
        tableConnectionIndex++;
      });

    }
   
  
    return totalSeats;
  };

  const UpdatePhoneNumber = (isTrue) => {
    //dispatch(updateReservation(current_reservation_id, {is_phone_confirmed: isTrue}))
    dispatch(postPhoneOrEmailConfirmation(current_reservation_id, 'phone'))
    dispatch_reservation_wait();
    setShowPhoneModal(!showPhoneModal);
  };
  


  return (
    <Container style={{padding:'10px'}}>
      <Row>
        <Col sm={0} md={0} xl={4}>
          
          <SubMenu restaurant_id={id.restaurant_id}/>
        </Col>
        <Col sm={12} md={12} xl={8}>
        {isWaiting && <WaitCheckMark/>}
      <StyledCard>
        <CardTop>
          <Row>
          {!isAllReservations &&
            <Col xs={6} md={4}>
              <DateButton onClick={handleOpenDateModal}>
                <DateButtonLeft>
                  <FontAwesomeIcon icon={faCalendar} style={{color: "#ffffff", marginTop: '5px'}} />
                </DateButtonLeft>
                <DateButtonRight>
                  {dateFilter}
                </DateButtonRight>
                <WeekDayRight>
                  {
                    new Date(dateFilter).toLocaleDateString('pt-BR', { weekday: 'short' }).replace('.', '')
                  }
                </WeekDayRight>
              </DateButton>
            </Col>
          }
            <Col xs={12} md={4} style={{ margin: '5px'}}>
              <FontAwesomeIcon icon={faUsers} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '2px'}} />
              {totalPeople} / {totalReservations}
              <FontAwesomeIcon icon={faUsers} size='lg' style={{color: "#dbca09", marginLeft: '5px',marginRight: '5px', paddingTop: '2px'}} />
              {totalPeopleLeft} / {totalReservationsLeft}
            </Col>
            
            {!isAllReservations &&
              
              <Col xs={12}  >
                <FilterButton >
                  <FilterButtonLeft>
                  <FontAwesomeIcon icon={faFilter} style={{color: "#ffffff"}} />
                  </FilterButtonLeft>
                  <FilterButtonRight>
                    <Row style={{width:'100%', padding:'2px'}}>
                      <Col xs={12} style={{display:'flex',flexWrap:'nowrap'}}>
                        <FiltersComponent
                          restaurantId={id.restaurant_id}
                        />
                        <CustomSelectArrayOfObjects
                          items={fetchedFilters}
                          onSelectChange={handleSelectFilters}
                          selectedItems={selectedFilters}
                        />
                      </Col>
                      <Col xs={12}  style={{display:'flex',flexWrap:'nowrap'}}>
                        <FontAwesomeIcon icon={faMap} style={{color: "#56d28c", marginLeft: '8px', marginRight: '5px', paddingTop:'5px'}} />
                        <CustomSelectArray
                        items={updatedZones}
                        onSelectChange={handleCustomSelectPlaceOfTable}
                        selectedItems={placeFilter}
                        />
                      </Col>
                    </Row>
                  </FilterButtonRight>
                </FilterButton>
              </Col>
            }
            
            <Col xs={5}>
              <CreateZoneButton onClick={handleOpenCreateReservationModal}>
                <StyledH7>Reserva</StyledH7><LargeIconPlus size="xl" icon={faPlus} />
              </CreateZoneButton>
            </Col>
          </Row>
        </CardTop>
        <CardBottom>
            <SearchBar>
              <SearchInput
                  type="text"
                  placeholder="Procurar"
                  value={searchTerm}
                  onChange={handleChange}
              />
              <SearchIcon icon={faSearch} />
            </SearchBar>
        </CardBottom>
      </StyledCard>
      <FilterButtonDropDown selectedOption={dropDownFilterButton} setSelectedOption={setDropDownFilterButton}></FilterButtonDropDown>
      {Array.isArray(theReservations) && Array.isArray(selectedMultipleReservations) && selectedMultipleReservations.length > 0 &&
          <StyledCard>
            
            <CardBottom >
            
              <Row>
                <Col xs={12} className='mb-2'>
                  <Row>
                    <Col xs={4} style={{fontSize:'16px', color:'white'}}>
                      Tudo: 
                    <StyledCheckbox
                      className="form-check-input makeItGreen"
                      id="getEmailCheck"
                      onClick={() => handleSelectMultipleReservationsAll(theReservations)}
                      checked={
                        theReservations
                          .filter(r => r.status !== "Cancelled")
                          .every(r => selectedMultipleReservations.includes(r.token))
                      }
                      style={{marginTop: '6px', marginLeft:'5px'}}
                    />
                    </Col>
                    <Col xs={8} className='d-flex justify-content-end' style={{fontSize:'14px', color:'white'}}>
                      selecionadas:  {selectedMultipleReservations.length} / {theReservations.filter(r => r.status !== "Cancelled").length}

                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                <FormCheck>
                <div className='d-flex justify-content-end'>
                {
                  theReservations.length > 0 && 
                  theReservations.some(rt => rt.status !== "Accepted" && rt.status !== "Cancelled") && (
                    <Button onClick={() => handleShowAcceptModal(false)} style={{backgroundColor:'#3de3d8', color:'white'}}>
                      Aceitar <FontAwesomeIcon size='lg' icon={faSquareCheck} style={{color: "#ffffff", paddingLeft:'5px'}} />
                    </Button>
                  )
                }
                  <Button onClick={() => handleShowModal(false)} style={{backgroundColor:'#de6718',color:'white', marginLeft:'10px'}}>
                    Rejeitar <FontAwesomeIcon size='lg' icon={faRectangleXmark} style={{color: "#ffffff", paddingLeft:'5px'}} />
                  </Button>
                </div>
                </FormCheck>
                </Col>
              </Row>
            </CardBottom>
          </StyledCard>
      }
      
      <ReservationGrid>
        { sortedResults && sortedResults.length > 0 && restaurantTimeZone? (
          sortedResults.map((reservation, index) => {
            const combinedDateTime = `${reservation.date} ${reservation.time}`;
            const reservationDateTime = moment.tz(combinedDateTime, "YYYY-MM-DD HH:mm", restaurantTimeZone.timezone);
            const isPastReservation = reservationDateTime.isBefore(currentDateTime);
            // Check if the reservation date is today or in the past
            const isTodayOrPast = reservationDateTime.clone().startOf('day').isSameOrBefore(currentDateTime.clone().startOf('day'));
            
          return (
            <ReservationCard key={reservation.id}>
              
              <ReservationTop status={reservation.status}>
                <Row style={{marginBottom:'0px', width:'100%', maxHeight:'100%'}}>
                  <Col xs={8} style={{overflow: 'hidden', whiteSpace: 'nowrap'}}>
                    
                    <ReservationTitle 
                      status={reservation.status} 
                      onClick={() => showCustomerInfo(reservation.country_code, reservation.phone_number)} 
                      style={{cursor: 'pointer'}}
                    >
                      <FontAwesomeIcon size='lg' icon={faEye} style={{color: "#ffffff", paddingRight:'5px'}} />
                      {reservation.full_name}
                      
                    </ReservationTitle>
                    
                  </Col>
                  
                  <Col xs={3} style={{display:'flex', justifyContent:'end', marginTop:'2px'}}>
                    {reservation.is_phone_confirmed ?
                      <a style={{ color:'white', cursor: "pointer" }} 
                      onClick={() => handleSetPhoneNumber(reservation.country_code, reservation.phone_number, reservation.id, reservation.is_phone_confirmed)}
                      >
                              <FontAwesomeIcon size='lg' icon={faPhone}  />
                      </a>
                      :
                      <a style={{ color:'white', cursor: "pointer" }} 
                      onClick={() => handleSetPhoneNumber(reservation.country_code, reservation.phone_number, reservation.id)}
                    >
                            <FontAwesomeIcon size='lg' icon={faPhoneSlash}  /> Não confirmado
                    </a>
                    }
                  </Col>
                </Row>
                
                
              </ReservationTop>
              <Row>
                    <Col xs={12} md={8}>
                    <ReservationBot>

                <ReservationBotItems>
                  <FontAwesomeIcon icon={faStopwatch} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                  {reservation.time}
                </ReservationBotItems>
                <ReservationBotItems>
                  <FontAwesomeIcon icon={faUsers} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                  {reservation.number_of_people}
                </ReservationBotItems>
                  <ReservationBotItems>
                    <FontAwesomeIcon icon={faMap} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                    {reservation.table_place_preference 
                    ? reservation.table_place_preference.place_of_table 
                    : 'Qualquer Sala'}
                  </ReservationBotItems>
                {reservation.baby_chair != 0 && 
                  <ReservationBotItems>
                    <FontAwesomeIcon icon={faBaby} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                      {reservation.baby_chair}
                  </ReservationBotItems>
                }
                {reservation.accessability != 'not_needed' && 
                  <ReservationBotItems>
                    <FontAwesomeIcon icon={faWheelchairMove} size='lg' style={{color: "#5ace8c", marginRight: '5px', paddingTop: '3px'}} />
                      {reservation.accessability === 'wheel_chair' ? 'Cadeira de Rodas' : 'Moletas' }
                  </ReservationBotItems>
                }
              
                  <ReservationBotItemsContainer>
                  <ReservationBoxWrap>

                    {
                      (all_tables && reservation?.tablesused?.length >= 1 && reservation.status !== 'Cancelled') ? (
                        <>
                          {/* Use the TableIconSvg component as an icon */}
                          <TableIconSvg style={{marginRight: '3px', paddingBottom: '3px' }} />
                          {reservation.tablesused.split(',')
                            .map(tableNumber => parseInt(tableNumber, 10))  // Convert to integers
                            .filter(tableNumber => !Number.isNaN(tableNumber)) // Filter out invalid numbers
                            .sort((a, b) => a - b)  // Sort in ascending order
                            .map((tableNumber, index, arr) => {
                              // Search for a matching table object
                              const tableObj = all_tables.find(table => table.id === tableNumber);
                              // Use tableNumber as a key if it's unique, otherwise fallback to index
                              const key = tableNumber ?? index;

                              return (
                                <span key={key} style={{ display: 'inline' }}>
                                  {/* Only show a comma for elements after the first one */}
                                  {index > 0 && ', '}
                                  {tableObj ? (
                                    <span style={{ color: '#5ace8c' }}>
                                      {tableObj.table_number}
                                    </span>
                                  ) : (
                                    <span style={{ color: 'red' }}>
                                      Não tem mesas selecionadas
                                    </span>
                                  )}
                                </span>
                              );
                            })}
                        </>
                      ) : null
                    }

                  
                 
                  {reservation.message != '' &&
                      <ReservationBotItemsContainer>
                      <div style={{color:'#f5bf42'}}>
                        <FontAwesomeIcon icon={faEnvelope} size='lg' style={{color: "#f5bf42", marginRight: '5px',marginLeft: '5px', paddingTop: '3px'}} />
                        Nota: {reservation.message}
                      </div>
                      <div style={{color:'#c95d32'}}>
                        <FontAwesomeIcon icon={faEnvelope} size='lg' style={{color: "#c95d32", marginRight: '5px',marginLeft: '5px', paddingTop: '3px'}} />
                        {reservation.last_chat_sender}: {reservation.last_chat_message}
                      </div>
                      </ReservationBotItemsContainer>
                  }
                  {reservation.not_enough_seats &&
                    <ReservationBotItemsContainer>
                      <p style={{color: "red",}}>
                        <>
                          Faltam lugares
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: "#d15823", fontSize:"1.3rem", marginLeft: '5px'}} />
                        </>
                      </p>
                    </ReservationBotItemsContainer>
                        
                  }
                </ReservationBoxWrap>
                </ReservationBotItemsContainer>
                <ReservationBotItems>
                    <ReservationChat 
                        reservation={reservation}
                      />
                </ReservationBotItems>
                
                {reservation.status === 'Edited' &&
                <ReservationBotItemsContainer style={{marginLeft:'10px'}}>
                  <CompareChanges before={reservation.history.find(item => item.history_id === reservation.restore_version_id)} after={reservation} />
                </ReservationBotItemsContainer>
                }
                
                <ReservationBotItemsContainer style={{marginTop:'10px'}}>
                {zones && 
                  <ReservationHistory
                    reservationHistory={reservation.history}
                    timezone={restaurantTimeZone.timezone}
                    all_tables={all_tables}
                    zones_with_id={zones.zones_with_id}
                  />
                }
                </ReservationBotItemsContainer>
                
              </ReservationBot>
                    </Col>
                    <Col xs={12} md={4} style={{height:'140px'}}>
                    
                    </Col>

                </Row>
                {
                  reservation.status !== 'Cancelled' &&
                  (
                    (!managerInfo) ||  // Show if managerInfo is false (always)
                    (managerInfo && new Date(reservation.date) > new Date(new Date().setDate(new Date().getDate() - 2)) && managerInfo.edit_reservations)  // Show if managerInfo is true and reservation date is at least one day in the future
                  ) ?
                  <StyledIcon 
                    onClick={() => handleOpenEditReservationModal(reservation.token)} 
                    icon={faFilePen} 
                    style={{color: "#ffffff", position: 'absolute', right: '10px', top: '3px', fontSize: '20px', cursor: 'pointer'}} 
                  />
                  :
                  <> <StyledIcon icon={faLock} style={{color: "#ffffff", position: 'absolute', right: '10px', top: '3px', fontSize: '20px', cursor: 'pointer'}}  /> </>
                }
                <ReservationStatusBox
                  reservation={reservation}
                  updateSearchResultsTableNumber={updateSearchResultsTableNumber}
                  index={reservation.id}
                  updateReservations={dispatch_reservation_wait}
                  reservations={sortedResults}
                  fetchAllReservations={dispatch_reservation_wait}
                  setTokens={setTokens}
                />
                
                
            </ReservationCard>
          )})
        ) : error ? (
          <Messages variant="error">{error}</Messages>
        ) : (
          <p>Não foi encontrada nenhuma reserva para os filtros definidos</p>
        )}
      </ReservationGrid>
      {tables &&
        <TablesModal
        show={showTablesModal}
        tables_list={tables}
        onClose={() => setShowTablesModal(false)}
        onSave={handleModalSave}
        initialSelectedTables={usedTables}
        token={tokena}
        id={restaurant_id}
      />
      }

        <Modal show={showPhoneModal} onHide={() => setShowPhoneModal(!showPhoneModal)}>
            <Modal.Header>
              <div style={{fontSize:'20px'}}>
              +{completePhone}
              </div>
              
            </Modal.Header>
            <Modal.Footer>
              {!phoneIsConfirmed &&
              <Button  style={{background:'#9FBBC1', border:'solid #9FBBC1 1px'}} onClick={() => UpdatePhoneNumber(1)}> 
                  Confirmar numero
                  <FontAwesomeIcon size='lg' icon={faCheck}  style={{marginLeft:'7px', color:'#ffffff'}}/>
              </Button>
              
              }
              
            <a href={`tel:${'+' + completePhone}`} style={{cursor: "pointer" }}>
              <Button> 
                        Ligar 
                  <FontAwesomeIcon size='lg' icon={faPhone}  style={{marginLeft:'7px'}}/>
              </Button>
            </a>
            
              <Button  style={{background:'#EE754C', border:'solid #EE754C 1px'}} onClick={() => setShowPhoneModal(!showPhoneModal)}> 
                        Fechar
                  <FontAwesomeIcon size='lg' icon={faXmark}  style={{marginLeft:'7px'}}/>
              </Button>
            </Modal.Footer>
      </Modal>

      <Modal show={showNoTablesMessage} onHide={() => setShowNoTablesMessage(!showNoTablesMessage)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção! Reserva restaurada sem mesas suficientes.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
              <Button variant="success" onClick={handleResetAttendance}>
                ok
              </Button>
        </Modal.Footer>
      </Modal>


      {(selectedReservation || selectedMultipleReservations) && (
        <>
          <Modal show={showAcceptModal} onHide={handleCloseAcceptModal}>
            <Modal.Header closeButton>
              <Modal.Title>Aceitar Reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Aceitar seguintes reservas:
              <div className='acceptRejectModal'>
                {Array.isArray(theReservations) && acceptMultipleReservations ?
                  <>
                    {theReservations
                    .filter(rt => selectedMultipleReservations.includes(rt.token))
                    .map((rt, index) => (
                      <div key={index}>
                        <p>{rt.full_name} / {rt.date} / {rt.time}</p>
                      </div>
                    ))}
                  </>
                  : 
                  <>
                  {Array.isArray(theReservations) && theReservations 
                    .filter(rt => tokens.includes(rt.token))
                    .map((rt, index) => (
                      <div key={index}>
                        <p>{rt.full_name} / {rt.date} / {rt.time}</p>
                      </div>
                    ))}
                  </>
                  
                }
              </div>
              

            </Modal.Body>
            <Modal.Footer>
            {isLoadingAccept?
              <Loader></Loader>:
              <SuccessButton variant="success" onClick={() => handleAccept()}>
                Sim
              </SuccessButton>
              }
              <DangerButton variant="secondary" onClick={handleCloseAcceptModal}>
                Não
              </DangerButton>
              
              
            </Modal.Footer>
          </Modal>
        </>
      )}



        </Col>
      </Row>
      {isReservationModalOpen && 
        <Modal show={isReservationModalOpen} onHide={setIsReservationModalOpen}>
          <Modal.Header closeButton>
            {isCreatingReservation &&
            <CustomSelect
              items={reservation_or_waiting_list_options}
              onSelectChange={setReservation_or_waiting_list}
              selectedItem={reservation_or_waiting_list}
            />
            }
          </Modal.Header>
          <ReservationScreen
            from_restaurant={true}
            creating={isCreatingReservation}
            setCreating={(value) => setIsCreatingReservation(value)}
            isEditModalOpen={isReservationModalOpen}
            setIsEditModalOpen={setIsReservationModalOpen}
            restaurantId={restaurant_id}
            handleCloseReservationModal={handleCloseReservationModal}
            token_from_restaurant={tokens}
            setTokens={setTokens}
            reset={reset}
            reservation_or_waiting_list={reservation_or_waiting_list}
          />
        </Modal>
      
      }
      

      <ShowCustomerInfo
        phone_number={completePhone}
        restaurant_id={id.restaurant_id}
        isModalActive={isShowCustomerInfoModalActive}
        setIsModalActive={setIsShowCustomerInfoModalActive}
      />

      { showDateModal &&
        <ModalBackgroundToCustomDate>
          <CustomDateContainer  onHide={handleCloseDateModal}>
            <CustomDatePickerReservationSums
                handleTimeChange={ (value) => setDateFilter(value)}
                handleCloseDateModal={handleCloseDateModal}
                date={dateFilter}
            />
          </CustomDateContainer>
        </ModalBackgroundToCustomDate>
      }
    <LoaderComponent
      activate={activateLoader || isCheckCancelLoading || isLoadingCancel}
      theSize={'o'}
    />
    <NotificationBell restaurant_id={id.restaurant_id} allReservations={all_reservations}></NotificationBell>
    </Container>
  );
  
  // ...
  }
  export default ReservationFromRestaurantScreen;