import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faUser, faNoteSticky } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Grid = styled.div`
  padding:10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  gap: 15px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #00796b #f0f0f0;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #00796b;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #005b4f;
  font-size: 1.2em;
  font-weight: bold;
  height: 50px;
  border: 1px solid ${(props) => (props.selected ? '#005b4f' : '#00796b')} ;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const AddButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00796b;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #005b4f;
  }
`;

const NumberOfPeopleSelector = ({ numPeople, setNumPeople }) => {
  const [array, setArray] = useState(16);

  const handleAddPeople = () => {
    setArray(array + 16);
  };

  const handleBoxClick = (number) => {
    setNumPeople(number);
  };

  return (
    <Container>
        <span style={{borderBottom:'1px solid black', paddingBottom:'10px', color:'black'}}><FontAwesomeIcon icon={faUser} /> Número de pessoas</span>
      <Grid>
        {Array.from({ length: array }, (_, index) => (
          <Box
            key={index}
            selected={array === index + 1}
            onClick={() => handleBoxClick(index + 1)}
          >
            {index + 1}
          </Box>
        ))}
      </Grid>
      <AddButton onClick={handleAddPeople}>+ Pessoas</AddButton>
    </Container>
  );
};

export default NumberOfPeopleSelector;
